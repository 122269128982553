import React, { Component } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { instance } from "./../../config";

class Print extends React.Component {
  constructor(props) {
    super(props);
    this.state = { listBankChecks: [] };

    this.componentRef = React.createRef();
    this.componentRef2 = React.createRef();
  }
  async componentDidMount() {
    const API = process.env.REACT_APP_API;
    const listBankChecks = await instance().get(
      `${API}/bankChek/operation/${this.props.idOperation}`
    );

    this.setState({ listBankChecks: listBankChecks.data.data });

    if (this.componentRef2) {
      this.componentRef2.handlePrint();
    }
  }

  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <div />}
          content={() => this.componentRef}
          ref={(el) => (this.componentRef2 = el)}
        />
        <ComponentPrint
          listBankChecks={this.state.listBankChecks}
          ref={(el) => (this.componentRef = el)}
          {...this.props}
        />
      </div>
    );
  }
}

class ComponentPrint extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          marginTop: 24,
          marginBottom: 24,
          marginRight: 42,
          marginLeft: 42,
        }}
      >
        <p
          class="MsoNormal"
          align="right"
          style={{
            marginTop: 0,
            marginRight: 75,
            marginBottom: 14,
            marginLeft: 5,
            textAlign: "right",
            lineHeight: "175%",
          }}
        >
          , {this.props.currentDateFormated}.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2 style={{ marginBottom: 8, fontSize: 16 }}>
            TERMO ADITIVO CONTRATUAL Nº {this.props.idOperation}
          </h2>
        </div>
        <table
          border="0"
          cellspacing="0"
          cellpadding="0"
          width="640"
          style={{
            width: 479,
            borderCollapse: "collapse",
            msoYftiTbllook: 1184,
            msoPaddingAlt: "0cm 0cm 0cm 0cm",
          }}
        >
          <tbody>
            <tr style={{ height: 40 }}>
              <td
                valign="top"
                style={{ width: 1000, padding: "0cm 0cm 0cm 0cm", height: 40 }}
              >
                <b>
                  <p
                    class="MsoNormal"
                    style={{
                      marginTop: 0,
                      marginRight: 0,
                      marginBottom: 2.05,
                      marginLeft: 0,
                      textIndent: 0,
                      fontSize: 12,
                      lineHeight: "100%",
                    }}
                  >
                    Vendedor-Contratante
                  </p>
                </b>
                <p
                  class="MsoNormal"
                  style={{
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 2.05,
                    marginLeft: 0,
                    fontSize: 12,
                    textIndent: 0,
                    lineHeight: "100%",
                  }}
                >
                  Empresa: {this.props.clientName}
                </p>
                <p
                  class="MsoNormal"
                  style={{
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 2.05,
                    marginLeft: 0,
                    textIndent: 0,
                    fontSize: 12,
                    lineHeight: "100%",
                  }}
                >
                  CNPJ:
                </p>
              </td>
              <td
                valign="top"
                style={{
                  padding: "0cm 0cm 0cm 0cm",
                  width: 300,
                  height: 40.0,
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <b style={{ msoBidiFontWeight: "normal" }}>
                    <p
                      class="MsoNormal"
                      style={{
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 2.05,
                        marginLeft: 0,
                        fontSize: 12,
                        textIndent: 0,
                        lineHeight: "100%",
                      }}
                    >
                      Comprador-Contratada
                    </p>
                  </b>
                  <p
                    class="MsoNormal"
                    style={{
                      marginTop: 0,
                      mmarginRight: 0,
                      marginBottom: 2.05,
                      marginLeft: 0,
                      textIndent: 0,
                      fontSize: 12,
                      lineHeight: "100%",
                    }}
                  >
                    Empresa: MultCl
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      width: 200,
                      marginRight: 0,
                      marginBottom: 2.05,
                      marginLeft: 0,
                      textIndent: 0,
                      fontSize: 12,
                      lineHeight: "100%",
                    }}
                  >
                    CNPJ: Registro Anfac: 2368/02
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          class="MsoNormal"
          align="center"
          style={{
            marginTop: 18,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 8.05,
            textAlign: "center",
            fontSize: 12,
            lineHeight: "100%",
          }}
        >
          FOMENTO MERCANTIL - Compra de Créditos à vista
        </p>
        <div align="center">
          <table
            class="MsoTableGrid"
            border="0"
            cellspacing="0"
            cellpadding="0"
            width="712"
            style={{
              width: 700,
              bordercollapse: "collapse",
              msoYftiYbllook: 1184,
              msoPaddingAlt: "4.4pt 3.3pt 0cm 3.0pt",
            }}
          >
            <tbody>
              <tr
                style={{
                  marginTop: 0,
                  msoYftiIrow: 0,
                  msoYftiFirstrow: "yes",
                  height: 14.55,
                }}
              >
                <td
                  width="120"
                  valign="bottom"
                  colspan="4"
                  style={{
                    width: 90.0,
                    border: "solid black 1.0pt",
                    msoBorderTopAlt: "solid black .75pt",
                    msoBorderLeftAlt: "solid black .75pt",
                    msoBorderBottomAlt: "solid black .75pt",
                    background: "silver",
                    height: 14.55,
                  }}
                >
                  <p
                    class="MsoNormal"
                    style={{
                      marginTop: 0,
                      marginRight: 0,
                      marginBottom: 0,
                      marginLeft: 4.5,
                      textIndent: 0,
                      lineHeight: "100%",
                    }}
                  >
                    <b style={{ msoBidiFontWeight: "normal" }}>
                      <span
                        style={{
                          fontSize: 12.0,
                          msoBidiFontSize: 11.0,
                          lineHeight: "100%",
                        }}
                      >
                        BORDERÔ
                      </span>
                    </b>
                  </p>
                </td>
              </tr>
              <tr style={{ msoYftiIrow: 1, height: 12.85 }}>
                <td
                  width="120"
                  valign="bottom"
                  style={{
                    width: 90.0,
                    borderTop: "none",
                    borderLeft: "solid black 1.0pt",
                    borderBottom: "solid black 1.5pt",
                    borderRight: "solid black 1.0pt",
                    msoBorderBottomAlt: "solid black 1.5pt",
                    background: "white",
                    height: 12.85,
                  }}
                >
                  <p
                    align="center"
                    style={{
                      margintop: 0,
                      marginright: 0,
                      marginleft: 0.7,
                      fontSize: 10,

                      textIndent: 0,
                      lineHeight: "100%",
                    }}
                  >
                    Codigo
                  </p>
                </td>
                <td
                  width="120"
                  valign="bottom"
                  style={{
                    width: 90.0,
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1.5px",
                    borderRight: "solid 1px",
                    background: "white",
                    padding: "4.4pt 3.3pt 0cm 3.0pt",
                    height: 12.85,
                  }}
                >
                  <p
                    align="center"
                    style={{
                      margintop: 0,
                      marginright: 0,
                      marginleft: 0.7,
                      fontSize: 10,
                      textIndent: 0,
                      lineHeight: "100%",
                    }}
                  >
                    Vencimento
                  </p>
                </td>
                <td
                  width="120"
                  valign="bottom"
                  style={{
                    width: 90.0,
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1.5pt",
                    borderRight: "solid black 1.0pt",
                    msoBorderTopAlt: "solid black .75pt",
                    msoBorderLeftAlt: "solid black .75pt",
                    msoBorderAlt: "solid black .75pt",
                    msoBorderBottomAlt: "solid black 1.5pt",
                    background: "white",
                    padding: "4.4pt 3.3pt 0cm 3.0pt",
                    height: 12.85,
                  }}
                >
                  <p
                    class="MsoNormal"
                    align="center"
                    style={{
                      margintop: 0,
                      marginRight: 0,
                      marginLeft: 15.75,
                      textIndent: 0,
                      fontSize: 10,
                      lineHeight: "100%",
                    }}
                  >
                    Valor
                  </p>
                </td>
                <td
                  width="352"
                  valign="bottom"
                  style={{
                    width: 90.0,
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid black 1.5pt",
                    borderRight: "solid black 1.0pt",
                    msoBorderTopAlt: "solid black .75pt",
                    msoBorderLeftAlt: "solid black .75pt",
                    msoBorderAlt: "solid black .75pt",
                    msoBorderBottomAlt: "solid black 1.5pt",
                    background: "white",
                    padding: "4.4pt 3.3pt 0cm 3.0pt",
                    height: 12.85,
                  }}
                >
                  <p
                    class="MsoNormal"
                    align="center"
                    style={{
                      margintop: 0,
                      marginRight: 0,
                      marginLeft: 15.75,
                      fontSize: 10,
                      textIndent: 0,
                      lineHeight: "100%",
                    }}
                  >
                    Sacado
                  </p>
                </td>
              </tr>
              {this.props.listBankChecks &&
                this.props.listBankChecks.map((bankChek) => {
                  return (
                    <React.Fragment key={bankChek.id}>
                      <tr style={{ msoYftiIrow: 2, height: 12.75 }}>
                        <td
                          width="120"
                          valign="top"
                          style={{
                            width: 90.0,
                            borderTop: "none",
                            borderLeft: "solid black 1.0pt",
                            borderBottom: "solid black 1.5pt",
                            borderRight: "solid black 1.0pt",
                            msoBorderTopAlt: "solid black .75pt",
                            msoBorderAlt: "solid black .75pt",
                            msoBorderBottomAlt: "solid black 1.5pt",
                            background: "white",
                            padding: "4.4pt 3.3pt 0cm 3.0pt",
                            height: 12.85,
                          }}
                        >
                          <p
                            class="MsoNormal"
                            align="center"
                            style={{
                              margintop: 0,
                              marginright: 0,
                              marginbottom: 0,
                              marginleft: 0.7,
                              textIndent: 0,
                              fontSize: 10,
                              lineHeight: "100%",
                            }}
                          >
                            {bankChek.id}
                          </p>
                        </td>
                        <td
                          width="120"
                          valign="top"
                          style={{
                            width: 90.0,
                            borderBottom: "solid black 1.5pt",
                            borderRight: "solid black 1.0pt",
                            msoBorderTopAlt: "solid black .75pt",
                            msoBorderLeftAlt: "solid black .75pt",
                            msoBorderAlt: "solid black .75pt",
                            msoBorderBottomAlt: "solid black 1.5pt",
                            background: "white",
                            padding: "4.4pt 3.3pt 0cm 3.0pt",
                            height: 12.85,
                          }}
                        >
                          <p
                            class="MsoNormal"
                            align="center"
                            style={{
                              margintop: 0,
                              marginright: 0,
                              marginbottom: 0,
                              marginleft: 0.7,
                              marginBottom: 0.0001,
                              fontSize: 10,

                              textIndent: 0,
                              lineHeight: "100%",
                            }}
                          >
                            {moment(bankChek.due_date).format("DD/MM/YYYY")}
                          </p>
                        </td>
                        <td
                          width="120"
                          valign="top"
                          style={{
                            width: 90.0,
                            borderTop: "none",
                            borderLeft: "none",
                            borderBottom: "solid black 1.5pt",
                            borderRight: "solid black 1.0pt",
                            msoBorderTopAlt: "solid black .75pt",
                            msoBorderLeftAlt: "solid black .75pt",
                            msoBorderAlt: "solid black .75pt",
                            msoBorderBottomAlt: "solid black 1.5pt",
                            background: "white",
                            padding: "4.4pt 3.3pt 0cm 3.0pt",
                            height: 12.85,
                          }}
                        >
                          <p
                            class="MsoNormal"
                            align="center"
                            style={{
                              margintop: 0,
                              marginright: 0,
                              marginbottom: 0,
                              marginleft: 0.7,
                              marginBottom: 0.0001,
                              textIndent: 0,
                              fontSize: 10,

                              lineHeight: "100%",
                            }}
                          >
                            R$
                            {String(
                              bankChek.value.toLocaleString("pt-BR", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })
                            )}
                          </p>
                        </td>
                        <td
                          width="352"
                          valign="top"
                          style={{
                            width: 264.3,
                            bordertop: "none",
                            borderLeft: "none",
                            borderBottom: "solid black 1.5pt",
                            borderRight: "solid black 1.0pt",
                            msoBorderTopAlt: 1.5,
                            msoBorderLeftAlt: 0.75,
                            msoBorderBottomAlt: 1.5,
                            msoBorderRightAlt: 0.75,
                            msoBorderColorAlt: "black",
                            msoBorderStyleAlt: "solid",
                            background: "white",
                            padding: "4.4pt 3.3pt 0cm 3.0pt",
                            height: 12.75,
                          }}
                        >
                          <p
                            class="MsoNormal"
                            align="center"
                            style={{
                              margintop: 0,
                              marginright: 0,
                              marginbottom: 0,
                              marginleft: 0.7,
                              fontSize: 10,

                              marginBottom: 0.0001,
                              textIndent: 0,
                              lineHeight: "100%",
                            }}
                          >
                            {bankChek.emitterName}
                          </p>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
        <p
          class="MsoNormal"
          style={{
            marginTop: 8,
            marginRight: 9.45,
            marginLeft: 0.5,
          }}
        ></p>
        <p
          class="MsoNormal"
          style={{
            marginTop: 0,
            marginRight: 9.45,
            marginBottom: 10.75,
            marginLeft: 0.5,
            fontSize: 12,
            lineHeight: "109%",
          }}
        >
          <span style={{ msoSpacerun: "yes" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          O presente Termo Aditivo é formalizado de acordo com a cláusula 3ª do
          Contrato de Fomento Mercantil nº , firmado em , celebrado com amparo
          nos arts. 191 a 220 do Código Comercial e no art. 1.216&nbsp; do&nbsp;
          Código Civil, bem como nos termos do art. 28 - § 1º alínea "c"-4 da
          Lei 8.981, de 20 de janeiro de 1995, e da Resolução nº 2.144, de 22 de
          fevereiro de 1995.
        </p>
        <p
          class="MsoNormal"
          style={{ marginRight: 9.45, lineHeight: "109%", fontSize: 12 }}
        >
          <span style={{ msoSpacerun: "yes" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          A CONTRATADA recebe, neste ato, a documentação, referente aos títulos
          relacionados no preâmbulo deste aditivo, responsabilizando-se a
          CONTRATANTE pela origem e legitimidade dos mesmos. A CONTRATANTE
          ratifica, neste ato, todas as condições estabelecidas nas cláusulas do
          Contrato de Fomento Mercantil acima referido, e, toma a iniciativa de
          recomprar qualquer dos títulos, ora negociados, de acordo com as
          cláusulas 12 e 19, respectivamente, juntando, para tanto, N.P.
          correspondente ao valor de face deste borderô, com vencimento à vista,
          em garantia dos vícios de origem, que, após o pagamento de todos os
          títulos lançados neste Aditivo, será devolvida à CONTRATANTE,
          devidamente inutilizada. Por este Aditivo acertam a entrega dos
          títulos de crédito acima relacionados, bem como a remuneração pelos
          serviços que a CONTRATADA deve prestar à CONTRATANTE, conforme se
          demonstra a seguir.
        </p>
        <p
          class="MsoNormal"
          style={{
            margintop: 0,
            marginRight: 9.45,
            marginLeft: 0.5,
          }}
        ></p>
        <p
          class="MsoNormal"
          align="left"
          style={{
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            paddingTop: 8,
            marginLeft: 1.5,
            textIndent: 0,
            lineHeight: "100%",
          }}
        >
          <b style={{ msoBidiFontWeight: "normal" }}>
            <span
              style={{
                fontSize: 15,
                msoBidiFontSize: 11.0,
                lineHeight: "100%",
              }}
            >
              DEMONSTRATIVO DA OPERAÇÃO
            </span>
          </b>
        </p>
        <div align="left">
          <table
            class="MsoTableGrid"
            border="0"
            cellspacing="0"
            cellpadding="0"
            style={{
              borderCollapse: "collapse",
              msoYftiTbllook: 1184,
            }}
          >
            <tbody>
              <tr
                style={{
                  msoYftiIrow: 0,
                  msoYftiFirstrow: "yes",
                  height: 121.45,
                }}
              >
                <td
                  width="216"
                  valign="top"
                  style={{
                    width: 500,
                    height: 121.45,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 0,
                      fontSize: 12,
                      marginBottom: 0,
                      margin: 0,
                    }}
                  >
                    <b style={{ msoBidiFontWeight: "normal" }}>
                      Total dos títulos negociados
                    </b>

                    <p
                      style={{
                        margin: 0,
                        textIndent: 0,
                        marginBottom: 0,
                        paddingRight: 150,
                      }}
                    >
                      (=)
                    </p>
                  </div>

                  <p
                    style={{
                      marginTop: 0,
                      marginRight: 0,
                      marginBottom: 2.05,
                      marginLeft: 0,
                      textIndent: 0,
                      fontSize: 12,

                      lineHeight: "100%",
                    }}
                  >
                    Diferencial de compra dos títulos
                  </p>

                  <p
                    style={{
                      marginTop: 0,
                      marginRight: 0,
                      marginBottom: 2.05,
                      marginLeft: 0,
                      textIndent: 0,
                      fontSize: 12,

                      lineHeight: "100%",
                    }}
                  >
                    <b style={{ msoBidiFontWeight: "normal" }}>
                      Desembolso líquido
                    </b>
                  </p>
                </td>

                <td
                  width="184"
                  valign="top"
                  align="right"
                  style={{
                    width: 100,
                    height: 121.45,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p
                    class="MsoNormal"
                    style={{
                      marginTop: 0,
                      marginRight: 0,
                      marginBottom: 2.05,
                      marginLeft: 6.0,
                      textIndent: 0,
                      fontSize: 12,

                      lineHeight: "100%",
                    }}
                  >
                    <b style={{ msoBidiFontWeight: "normal" }}>
                      R$
                      {this.props.totalValue.toLocaleString("pt-BR", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </b>
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginRight: 0,
                      marginBottom: 2.05,
                      marginLeft: 0,
                      textIndent: 0,
                      fontSize: 12,

                      lineHeight: "100%",
                    }}
                  >
                    R${" "}
                    {this.props.clearAmount.toLocaleString("pt-BR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      marginRight: 0,
                      fontSize: 12,

                      marginBottom: 2.05,
                      marginLeft: 0,
                      textIndent: 0,
                      lineHeight: "100%",
                    }}
                  >
                    <b>R$ {this.props.amountPayable.toLocaleString("pt-BR")}</b>
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  valign="mid"
                  style={{
                    width: "162.0pt",
                  }}
                >
                  <p
                    class="MsoNormal"
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      textIndent: "0cm",
                      fontSize: 12,
                      lineHeight: "107%",
                    }}
                  >
                    Testemunhas:
                  </p>
                </td>

                <td
                  width="216"
                  valign="mid"
                  style={{
                    width: "162.0pt",
                    padding: "0cm 0cm 0cm 0cm",
                    height: "40.0pt",
                  }}
                >
                  <p
                    class="MsoNormal"
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      textIndent: "0cm",
                      fontSize: 12,
                      lineHeight: "107%",
                    }}
                  >
                    Data: {this.props.currentDate}
                  </p>
                </td>
              </tr>
              <tr
              // style="mso-yfti-irow:2;mso-yfti-lastrow:yes;height:43.45pt"
              >
                <td
                  width="450"
                  valign="top"
                  // style="width:162.0pt;padding:0cm 0cm 0cm 0cm;height:43.45pt"
                >
                  <p
                    class="MsoNormal"
                    style={{ paddingBottom: 16 }}
                    // style="margin-top:0cm;margin-right:0cm;margin-bottom:20.05pt;margin-left:0cm;text-indent:0cm;line-height:107%"
                  >
                    ------------------------------------------------------
                  </p>
                  <p
                    class="MsoNormal"
                    // style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;line-height:107%"
                  >
                    ------------------------------------------------------
                  </p>
                </td>
                <td
                  width="450"
                  valign="top"
                  // style="width:162.0pt;padding:0cm 0cm 0cm 0cm;height:43.45pt"
                >
                  <p
                    class="MsoNormal"
                    style={{ marginBottom: 0 }}
                    // style="margin-top:0cm;margin-right:0cm;margin-bottom:20.05pt;margin-left:0cm;text-indent:0cm;line-height:107%"
                  >
                    ------------------------------------------------------
                  </p>
                  <p
                    class="MsoNormal"
                    style={{
                      marginTop: 4,
                      fontSize: 12,
                      marginBottom: 2,
                    }}
                    // style="margin-top:0cm;margin-right:0cm;margin-bottom:8.05pt;margin-left:117.75pt;text-indent:0cm;line-height:107%"
                  >
                    {this.props.clientName}
                  </p>
                  <p
                    class="MsoNormal"
                    // style="margin:0cm;margin-bottom:.0001pt;text-indent:0cm;line-height:107%"
                  >
                    ------------------------------------------------------
                  </p>
                </td>

                {/* <td
                    width="351"
                    colspan="2"
                    valign="top"
                    // style="width:263.1pt;padding:0cm 0cm 0cm 0cm;height:43.45pt"
                  >
                    <p
                      class="MsoNormal"
                      align="right"
                      // style="margin-top:0cm;margin-right:0cm;margin-bottom:2.05pt;margin-left:0cm;text-align:right;text-indent:0cm;line-height:107%"
                    >
                      -----------------------------------------------------------------------------------
                    </p>
                    <p
                      class="MsoNormal"
                      // style="margin-top:0cm;margin-right:0cm;margin-bottom:8.05pt;margin-left:117.75pt;text-indent:0cm;line-height:107%"
                    >
                      DEJAIR SD MOTOS
                    </p>
                    <p
                      class="MsoNormal"
                      align="right"
                      // style="margin:0cm;margin-bottom:.0001pt;text-align:right;text-indent:0cm;line-height:107%"
                    >
                      -----------------------------------------------------------------------------------
                    </p>
                  </td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Print;
