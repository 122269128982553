import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Dialog from "./../../components/Dialog";
import TextField from "@material-ui/core/TextField";
import Table from "./../../components/Table";
import Loading from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalDelete from "./../../components/ModalDelete";
import Moment from "moment";
import "moment/locale/pt-br";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InputMask from "react-input-mask";
import { cpf, cnpj } from "cpf-cnpj-validator";
import Divider from "@material-ui/core/Divider";
import { withUIContext } from "./../../contexts/UIContext";
import { instance } from "./../../config";
import { Card, CardContent, Typography } from "@material-ui/core";

class ListBankChecks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: {},
      negociation: {},
      client: {},
      dateOperation: "",
      newEmitterName: "",
      result: {},
      openModalDelete: false,
      // dueDate: "",
      value: "",
      emitters: [],
      editIdBankCheck: "",
      idBankChekToExclude: "",
      loading: false,
      loadingButton: false,
      createEmitter: false,
      emitterSelected: {},
      newDocument: "",
      newDocumentType: "",
      data: [],
      columns: [
        {
          name: "id",
          label: "Codigo",
          options: {
            filter: true,
            sort: false,
          },
        },

        {
          name: "document",
          label: "CPF/CNPJ",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "emitterName",
          label: "Nome",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "due_date",
          label: "Vencimento",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{Moment(value).format("DD/MM/YYYY")}</div>;
            },
          },
        },
        {
          name: "value",
          label: "Valor",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "actions",
          label: "Ações",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div style={{ width: "40%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <EditIcon
                      onClick={(e) => {
                        this.setState({
                          editIdBankCheck: tableMeta.rowData[0],
                          open: true,
                        });
                      }}
                    />
                    <DeleteIcon
                      onClick={() =>
                        this.setState({
                          openModalDelete: true,
                          idBankChekToExclude: tableMeta.rowData[0],
                        })
                      }
                    />
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };

    this.input = React.createRef();

    this.handleSaveEmitter = this.handleSaveEmitter.bind(this);
  }
  async getBankChekById(id) {
    const API = process.env.REACT_APP_API;

    const { data } = await instance().get(`${API}/bankChek/${id}`);

    return data;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.editIdBankCheck !== this.state.editIdBankCheck &&
      this.state.editIdBankCheck
    ) {
      const { data } = await this.getBankChekById(this.state.editIdBankCheck);
      if (data) {
        this.props.uiContext.setLastDueDate(data.due_date);

        this.setState({
          emitterSelected: {
            id: data.emitterId,
            name: data.emitterName,
            document: data.document,
            type_document: data.document_type,
            credit_limit: data.credit_limit,
            used_credit_limit: data.used_credit_limit,
            remaining_credit_limit: data.remaining_credit_limit,
          },
          // dueDate: Moment(data.due_date, "DD/MM/YYYY").format("DD-MM-YYYY"),
          // dueDate: data.due_date,

          value: data.value,
        });
      }
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });

    const API = process.env.REACT_APP_API;
    const idOperation = this.props.id;

    const bankCheks = await instance().get(
      `${API}/bankChek/operation/${idOperation}`
    );

    const client = await instance().get(
      `${API}/client/operation/${idOperation}`
    );

    this.loadEmitters();

    function ccyFormat(num) {
      return `R$ ${num.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}`;
    }

    this.setState({
      titles: bankCheks && bankCheks.data.titles,
      negotiation: bankCheks && bankCheks.data.negotiation,
      result: bankCheks && bankCheks.data.result,
      data:
        bankCheks &&
        bankCheks.data.data.map((item) => {
          return { ...item, value: ccyFormat(item.value) };
        }),
      client: client && client.data.data,
      dateOperation: bankCheks && bankCheks.data.date_operation,
      loading: false,
    });
  }

    async loadEmitters() {
      const API = process.env.REACT_APP_API;
      
      const emitters = await instance().get(
        `${API}/emitters/search`
      );

      const { emitterSelected } = this.state;
      if (Object.keys(emitterSelected).length !== 0) {
        emitters.data.data.unshift(emitterSelected);
        this.setState({emitters: emitters});
      }

      this.setState({
        emitterSelected: {},
        emitters: emitters && emitters.data.data,
      });
      
    }
  async createBankCheck() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const idOperation = this.props.id;
    const { emitterSelected, value } = this.state;

    const { data } = await instance().post(`${API}/bankChek`, {
      id_emitter: emitterSelected.id,
      id_operation: idOperation,
      due_date: this.props.uiContext.lastDueDate,
      value,
    });

    return data.idBankCheck;
  }

  async updateBankCheck() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const idOperation = this.props.id;
    const { emitterSelected, value, editIdBankCheck } = this.state;

    const { data } = await instance().put(
      `${API}/bankChek/${editIdBankCheck}`,
      {
        id_emitter: emitterSelected.id,
        id_operation: idOperation,
        due_date: this.props.uiContext.lastDueDate,
        value,
      }
    );

    return data;
  }

  errorParamsEmitter() {
    const { newEmitterName, newDocument, newDocumentType } = this.state;
    let params = [];
    let message = "Por favor, insira o(s) campo(s):";

    if (!newEmitterName) {
      params.push(" nome do emitente");
    }

    //returns immediatelly if emmiter update
    if ((this.state.emitterSelected.id)) {
      if (params.length) {
        return (message += params);
      }
      return;
    }

    //rest of the validation for creation
    if (
      !newDocument ||
      (newDocumentType === "cpf" && !cpf.isValid(newDocument))
    ) {
      params.push(" CPF/CNPJ invalido");
    }
    if (
      !newDocument ||
      (newDocumentType === "cnpj" && !cnpj.isValid(newDocument))
    ) {
      params.push(" CPF/CNPJ invalido");
    }
    if (!newDocumentType) {
      params.push(" tipo de documento(CPF ou CNPJ");
    }

    if (params.length) {
      return (message += params);
    }
  }

  errorParams() {
    const { emitterSelected, value } = this.state;
    let params = [];
    let message = "Por favor, insira o(s) campo(s):";

    if (!emitterSelected.id) {
      params.push(" emitente");
    }
    if (!this.props.uiContext.lastDueDate) {
      params.push(" data de vencimento");
    }
    if (!value) {
      params.push(" valor");
    }

    if (params.length) {
      return (message += params);
    }
  }

  handleSave = async () => {
    const { editIdBankCheck } = this.state;

    if (this.props.uiContext.lastDueDate <= this.state.dateOperation) {
      return alert("Você não pode colocar uma data de vencimento menor ou igual a data da operação.")
    }

    if (this.errorParams()) {
      return alert(this.errorParams());
    }
  
    const isEdit = !!editIdBankCheck;

    if (isEdit) {
      try {
        await this.updateBankCheck();

        const newDataList = this.state.data.map((item) => {
          if (item.id === editIdBankCheck) {
            return {
              id: item.id,
              emitterName: this.state.emitterSelected.name,
              due_date: Moment(
                this.props.uiContext.lastDueDate,
                "DD/MM/YYYY"
              ).format("DD-MM-YYYY"),
              value: this.state.value,
              document: this.state.emitterSelected.document,
              type_document: this.state.emitterSelected.type_document,
            };
          }
          return { ...item };
        });

        this.setState({
          open: false,
          data: newDataList,
        });

      } catch (err) {
        if (err.status === 400) {
          console.log(err)
          return alert(err.data.message);
        }
      } finally {

        this.setState({
          // open: false,
          loadingButton: false,
        });

        this.fetchData();
      }

    } else {

      try {
        const bankCheckId = await this.createBankCheck();

        const newValue = {
          id: bankCheckId,
          emitterName: this.state.emitterSelected.name,
          due_date: Moment(this.props.uiContext.lastDueDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          ),
          value: this.state.value,
          document: this.state.emitterSelected.document,
          type_document: this.state.emitterSelected.type_document,
        };

        this.setState({
          data: [...this.state.data, newValue],
          // emitterSelected: {},
          value: "",
          loadingButton: false,
          // dueDate: "",
          // open: false,
        });

        if (this.input) {
          setTimeout(() => {
            this.input.current.focus();
          }, 100);
        }
      } catch (err) {
        if (err.status === 400) {
          console.log(err)
          return alert(err.data.message);
        }
      } finally {

        this.setState({
          // open: false,
          loadingButton: false,
        });

      }
  
    }
    
    await this.fetchData();
    // window.location.reload();
  };

  async handleDelete() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const { idBankChekToExclude } = this.state;

    const newDataList = this.state.data.filter(
      (item) => item.id !== idBankChekToExclude
    );
    if (idBankChekToExclude) {
      const removeBankChek = await instance().delete(
        `${API}/bankChek/${idBankChekToExclude}`
      );

      if (removeBankChek) {
        this.setState({
          data: newDataList,
          openModalDelete: false,
          loadingButton: false,
        });

        return this.fetchData();
      }
    }
  }

  async handleSaveEmitter() {
    const { newEmitterName, newDocument, newDocumentType } = this.state;
    const API = process.env.REACT_APP_API;

    if (this.errorParamsEmitter()) {
      return alert(this.errorParamsEmitter());
    }

    try {
      const apiUrl = this.state.emitterSelected.id
        ? `${API}/emitter/${this.state.emitterSelected.id}`
        : `${API}/emitter`;

      const requestData = this.state.emitterSelected.id
        ? { 
          name: newEmitterName,
          document: this.state.emitterSelected.document.split('-')[0],
          documentType: this.state.emitterSelected.documentType,
        } : {
          name: newEmitterName,
          document: newDocument && newDocument.replace(/[^\d]+/g, ""),
          documentType: newDocumentType,
        };        

      const { data } = await instance().request({
        method: this.state.emitterSelected.id ? 'PUT' : 'POST',
        url: apiUrl,
        data: requestData,
      });


      // const emitters = await instance().get(`${API}/emitters`);

      this.setState({
        createEmitter: false,
        // emitters: emitters && emitters.data.data,
        open: true,
        emitterSelected: {
          id: data.idEmitter ?? this.state.emitterSelected.id,
          name: requestData.name,
          document: requestData.document,
          document_type: requestData.documentType,
        },
      });

      this.setState({
        newEmitterName: "",
        newDocument: "",
        newDocumentType: "",
      });
    } catch (err) {
      if (err.status === 400) {
        return alert("Este cpf/cnpj já existe no banco de dados.");
      }
    }
    this.fetchData();
  }

  handleClose = () => {
    this.setState({
      open: false,
      emitterSelected: {},
      value: "",
      emitters: [],
    });
  };

  render() {
    const { classes } = this.props;
    const {
      open,
      loading,
      columns,
      data,
      emitters,
      value,
      titles,
      negotiation,
      result,
      client,
    } = this.state;
    const idOperation = this.props.id;

    const defaultProps = {
      options: emitters,
      getOptionLabel: (option) => option.document,
    };

    function ccyFormat(num) {
      return ` ${num.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}`;
    }

    // const inputFocus = (input) => {
    //   if (input) {
    //     setTimeout(() => {
    //       input.focus();
    //     }, 100);
    //   }
    // };

    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              let currentDate = Moment(this.state.dateOperation).format("DD-MM-YYYY");
              let currentDateFormated = Moment(this.state.dateOperation).format("ll");
              return window.open(
                `/impressao/${this.props.id}/${negotiation ? ccyFormat(negotiation.clear_amount) : 0
                }/${client ? client.clientName : "sem nome"}/${titles ? ccyFormat(titles.total_value) : 0
                }/${negotiation ? ccyFormat(negotiation.amount_payable) : 0
                }/${currentDate}/${currentDateFormated}`
              );
            }}
            variant="contained"
            color="primary"
          >
            Imprimir
          </Button>

          {this.state.client &&
            this.state.client.isOldCalc &&
            this.state.client.isOldCalc === "S" ? (
            <Typography
              variant="caption"
              style={{
                color: "red",
                marginBottom: 8,
                marginTop: 10,
                paddingLeft: 32,
                marginRight: 16,
              }}
            >
              Esta operação está utilizando o calculo antigo de juros compostos,
              calculado o valor total pela media de dias, ao invés calcular para
              cada cheque separadamente.
            </Typography>
          ) : (
            ""
          )}

          <table className={classes.table}>
            <thead
              style={{
                display: "table-header-group",
                verticalAlign: "middle",
                borderColor: "inherit",
              }}
            >
              <tr>
                <th colSpan="3" className={classes.tableSubTitle}>
                  Cliente
                </th>
              </tr>
              <tr>
                <td style={{ fontSize: 18, textAlign: "center" }}>
                  {client && client.clientName}
                </td>
              </tr>
            </thead>
          </table>
          <table className={classes.table}>
            <thead>
              <tr>
                <th colSpan="2" className={classes.tableSubTitle}>
                  Data
                </th>
                <td
                  style={{
                    fontSize: 18,
                    marginLeft: 24,
                    textAlign: "center",
                  }}
                >
                  {client && client.dateOperation}
                </td>
                <th colSpan="2" className={classes.tableSubTitle}>
                  Juro
                </th>
                <td
                  style={{
                    fontSize: 18,
                    marginLeft: 24,
                    textAlign: "center",
                  }}
                >
                  {(client && client.rate) || 0}%
                </td>
              </tr>
              <tr>

                <th colSpan="2" className={classes.tableSubTitle}>
                  Limite
                </th>
                <td
                  style={{
                    fontSize: 18,
                    marginLeft: 24,
                    textAlign: "center",
                  }}
                >
                  {(client && client.credit_limit) ? `R$ ${ccyFormat(client.credit_limit)}` : '-'}
                </td>

                <th colSpan="2" className={classes.tableSubTitle}>
                  Limite Utilizado
                </th>
                <td
                  style={{
                    fontSize: 18,
                    marginLeft: 24,
                    textAlign: "center",
                  }}
                >
                  {(client && client.used_credit_limit) ? `R$ ${ccyFormat(client.used_credit_limit)}` : '-'}
                </td>
                <th colSpan="1" className={classes.tableSubTitle}>
                  Limite Restante
                </th>
                <td
                  style={{
                    fontSize: 18,
                    marginLeft: 24,
                    textAlign: "center",
                  }}
                >
                  {(client && client.remaining_credit_limit) ? `R$ ${ccyFormat(client.remaining_credit_limit)}` : '-'}
                </td>

              </tr>
            </thead>
          </table>
          <table
            className={classes.table}
          // style={{ marginTop: 48, marginBottom: 48 }}
          >
            <tbody>
              <tr>
                <th colSpan="3" className={classes.tableTitle}>
                  Titulos
                </th>
              </tr>
              <tr>
                <th className={classes.tableSubTitle}>Quantidade</th>
                <th className={classes.tableSubTitle}>Prazo médio</th>
                <th className={classes.tableSubTitle}>Valor total</th>
              </tr>
              <tr>
                <td className={classes.tableValues}>
                  {titles && titles.amount}
                </td>
                <td className={classes.tableValues}>
                  {(titles && titles.medium_term) || 0} dias
                </td>
                <td className={classes.tableValues}>
                  R${titles && ccyFormat(titles.total_value)}
                </td>
              </tr>
              <tr>
                <th colSpan="3" className={classes.tableTitle}>
                  Negociação
                </th>
              </tr>
              <tr>
                <th colSpan="2" className={classes.tableSubTitle}>
                  Diferencial
                </th>
                <th rowSpan="2" className={classes.tableSubTitle}>
                  Valor de <br /> Desembolso
                </th>
              </tr>
              <tr>
                <th className={classes.tableSubTitle}>Taxa</th>
                <th className={classes.tableSubTitle}>Valor</th>
              </tr>
              <tr>
                <td className={classes.tableValues}>
                  {negotiation && negotiation.effective_rate}%
                </td>
                <td className={classes.tableValues}>
                  R${negotiation && ccyFormat(negotiation.clear_amount)}
                </td>
                <td className={classes.tableValues}>
                  R${negotiation && ccyFormat(negotiation.amount_payable)}
                </td>
              </tr>
              <tr rowSpan="2">
                <th colSpan="3" className={classes.tableTitle}>
                  Resultado
                </th>
              </tr>
              <tr>
                <th className={classes.tableSubTitle}>Taxa efetiva</th>
                <th className={classes.tableSubTitle}>Lucro líquido</th>
                <th className={classes.tableSubTitle}>Taxa efetiva/mês</th>
              </tr>
              <tr>
                <td className={classes.tableValues}>
                  {result && result.effective_rate}%
                </td>
                <td className={classes.tableValues}>
                  R${negotiation && ccyFormat(negotiation.clear_amount)}
                </td>
                <td className={classes.tableValues}>
                  {result && result.effective_rate_month}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.content}>
          {loading ? (
            <div className={classes.loading}>
              <Loading size={40} />
            </div>
          ) : (
            <Table
              pagination={false}
              titleText="Cheques"
              data={data}
              columns={columns}
            >
              <Button
                onClick={() => {
                  if (this.input) {
                    setTimeout(() => {
                      this.input.current.focus();
                    }, 100);
                  }
                  
                  this.loadEmitters();
                  
                  this.setState({
                    open: true,
                    editIdBankCheck: 0,
                    emitterSelected: {},
                    createEmitter: false,
                    value: "",
                  });
                }}
                className={classes.button}
              >
                <AddIcon className={classes.addIcon} fontSize="large" />
              </Button>
            </Table>
          )}
        </div>
        <Dialog
          onClose={() => this.handleClose("open")}
          type="client"
          open={open}
          title={
            this.state.editIdBankCheck ? "Editar cheque" : "Adicionar cheque"
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 48,
              marginRight: 48,
              marginLeft: 48,
            }}
          >
            <TextField
              disabled
              id="standard-disabled"
              label="Operação"
              defaultValue={idOperation}
              style={{ margin: 18 }}
              margin="normal"
            />

            {this.state.createEmitter ? (
              <ArrowBackIosIcon
                onClick={() =>
                  this.setState({
                    emitterSelected: {},
                    createEmitter: false,
                  })
                }
                size="small"
                style={{
                  color: "red",
                  marginTop: 24,
                  marginLeft: 16,
                  cursor: "pointer",
                }}
              />
            ) : (
              <Autocomplete
                style={{ margin: 18  }}
                {...defaultProps}
                noOptionsText="Sem opções"
                onChange={(event, newValue) => {
                  this.setState({
                    emitterSelected: newValue,
                    newDocument: "",
                  });
                }}
                value={this.state.emitterSelected}
                renderInput={(params) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {(this.state.emitterSelected && this.state.emitterSelected.id) ?
                      (
                        <EditIcon
                          onClick={() =>
                            this.setState({
                              newEmitterName: this.state.emitterSelected.name,
                              createEmitter: true,
                            })
                          }
                          size="small"
                          style={{
                            color: "green",
                            marginTop: 24,
                            cursor: "pointer",
                          }}
                        />
                      )
                      :
                      (
                        <AddIcon
                          onClick={() =>
                            this.setState({
                              emitterSelected: {},
                              createEmitter: true,
                            })
                          }
                          size="small"
                          style={{
                            color: "green",
                            marginTop: 24,
                            cursor: "pointer",
                          }}
                        />
                      )}

                    <TextField
                      onChange={async (event) => {
                        const API = process.env.REACT_APP_API;
                        const characteres = event.target.value;

                        if (characteres.length >= 3) {
                          const { data } = await instance().get(
                            `${API}/emitters/search?filter=${characteres}`
                          );

                          this.setState({ emitters: data.data });
                        }

                        this.setState({ newDocument: characteres });
                      }}
                      value={this.state.emitterSelected}
                      {...params}
                      inputRef={this.input}
                      label="Emitente"
                      margin="normal"
                    />
                  </div>
                )}
              />
            )}

            {this.state.createEmitter && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Divider />
                <div
                  style={{
                    color: "#3f51b5",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {this.state.emitterSelected.id ? 'Alterar' : 'Novo'} emitente {this.state.emitterSelected.id ?? ''}
                </div>
                <TextField
                  value={this.state.newEmitterName}
                  onChange={(e) =>
                    this.setState({ newEmitterName: e.target.value })
                  }
                  style={{ marginBottom: 24, marginTop: 8 }}
                  required
                  label="Nome do emitente"
                />

                {!this.state.emitterSelected.id && (
                  <FormControl style={{ marginBottom: 12 }} component="fieldset">
                    <RadioGroup
                      value={this.state.newDocumentType}
                      onChange={(e) =>
                        this.setState({ newDocumentType: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="cpf"
                        control={<Radio color="primary" />}
                        label="CPF"
                      />
                      <FormControlLabel
                        value="cnpj"
                        control={<Radio color="primary" />}
                        label="CNPJ"
                      />
                    </RadioGroup>
                  </FormControl>
                )}

                {this.state.newDocumentType && (
                  <InputMask
                    mask={
                      this.state.newDocumentType === "cpf"
                        ? "999.999.999-99"
                        : "99.999.999/9999-99"
                    }
                    defaultValue={this.state.newDocument}
                    value={this.state.newDocument}
                    onChange={(e) =>
                      this.setState({ newDocument: e.target.value })
                    }
                    className={this.props.classes.textField}
                  >
                    {() => (
                      <TextField
                        defaultValue={this.state.newDocument}
                        required
                        label={
                          this.state.newDocumentType === "cpf" ? "CPF" : "CNPJ"
                        }
                      />
                    )}
                  </InputMask>
                )}

                <Button
                  style={{ color: "#3f51b5" }}
                  onClick={this.handleSaveEmitter}
                >
                  {this.state.emitterSelected.id ? 'Alterar' : 'Cadastrar'}  emitente
                </Button>
                <Divider />
              </div>
            )}

            {!this.state.createEmitter && (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.emitterSelected &&
                    this.state.emitterSelected.name}

                </div>

                <TextField
                  id="date"
                  label="Vencimento"
                  type="date"
                  value={this.props.uiContext.lastDueDate}
                  defaultValue="2017-05-24"
                  onChange={(e) => {
                    this.props.uiContext.setLastDueDate(e.target.value);
                  }}
                  style={{ margin: 18 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <CurrencyTextField
                  label="Valor"
                  variant="standard"
                  style={{ margin: 18 }}
                  value={value}
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  outputFormat="string"
                  onChange={(e, value) => this.setState({ value: value })}
                />

                <Card>
                  <CardContent>
                    <table>
                      {(this.state.emitterSelected && this.state.emitterSelected.credit_limit) ? (
                        <tr>
                          <React.Fragment>
                            <tr>
                              <td><strong>Limite do Emitente:</strong></td>
                            </tr>
                            <tr>
                              <td>R$ {ccyFormat(this.state.emitterSelected.credit_limit)}</td>
                            </tr>
                          </React.Fragment>
                        </tr>
                      ) : (
                        null
                      )}

                      {(this.state.emitterSelected && this.state.emitterSelected.credit_limit && this.state.emitterSelected.used_credit_limit) ? (
                        <tr>
                          <React.Fragment>
                            <tr>
                              <td><strong>Limite Usado pelo Emitente:</strong></td>
                            </tr>
                            <tr>
                              <td>R$ {ccyFormat(this.state.emitterSelected.used_credit_limit)}</td>
                            </tr>
                          </React.Fragment>
                        </tr>
                      ) : (
                        null
                      )}

                      {(this.state.emitterSelected && this.state.emitterSelected.credit_limit && this.state.emitterSelected.remaining_credit_limit) ? (
                        <tr>
                          <React.Fragment>
                            <tr>
                              <td><strong>Limite disponível para Emitente:</strong></td>
                            </tr>
                            <tr>
                              <td>R$ {ccyFormat(this.state.emitterSelected.remaining_credit_limit)}</td>
                            </tr>
                          </React.Fragment>
                        </tr>
                      ) : (
                        null
                      )}

                      {(this.state.emitterSelected && !this.state.emitterSelected.credit_limit && this.state.emitterSelected.used_credit_limit) ? (
                        <tr>
                          <React.Fragment>
                            <tr>
                              <td><strong>Cliente sem Limite de crédito cadastrado,<br/> Saldo em aberto:</strong></td>
                            </tr>
                            <tr>
                              <td>R$ {ccyFormat(this.state.emitterSelected.used_credit_limit)}</td>
                            </tr>
                          </React.Fragment>
                        </tr>
                      ) : (
                        null
                      )}

                    </table>

                  </CardContent>
                </Card>

              </React.Fragment>
            )}
          </div>
          {!this.state.createEmitter && (
            <Button onClick={this.handleSave} autoFocus color="primary">
              {this.state.loadingButton ? (
                <div className={classes.loading}>
                  <Loading size={40} />
                </div>
              ) : this.state.editIdBankCheck ? (
                "Editar"
              ) : (
                "Cadastrar"
              )}
            </Button>
          )}
        </Dialog>

        <ModalDelete
          name="cheque"
          message="Você tem certeza que deseja excluir este cheque ?"
          open={this.state.openModalDelete}
          handleClose={() => this.handleClose("openModalDelete")}
          handleDelete={() => this.handleDelete()}
          loading={this.state.loadingButton}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  tableTitle: {
    width: 1000,
    background: "#bbb",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
    padding: 2,
  },
  tableSubTitle: {
    heigth: 100,
    background: "#ccc",
    width: 140,
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    fontSize: 18,
    padding: 2,
  },
  tableValues: {
    textAlign: "center",
    paddingRight: 6,
    fontSize: 18,
  },
  addIcon: {
    color: "#ffff",
  },
  button: {
    backgroundColor: "#4caf50",
    marginBottom: 8,
    marginTop: 8,
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "flex-end",
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  table: {
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
});
export default withUIContext(withStyles(styles)(ListBankChecks));
