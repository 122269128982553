import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "./../../components/Dialog";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import Table from "./../../components/Table";
import Loading from "@material-ui/core/CircularProgress";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import ModalDelete from "./../../components/ModalDelete";
import { instance } from "./../../config";

class ClientScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isSearch: false,
      searchText: "",
      searchTimeout: null,
      openModalDelete: false,
      loading: false,
      count: 10,
      offset: 0,
      sortBy:  null,
      sortDirection: null,
      serverSideFilterList: [],
      page: 0,
      loadingButton: false,
      editIdClient: 0,
      idClientToExclude: 0,
      rowsPerPage: 10,
      name: "",
      rate: "",
      credit_limit: "",
      dataFilter: [],
      data: [],
      tmpData: [],
      columns: [
        {
          name: "id",
          label: "Codigo",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "name",
          label: "Nome",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "rate",
          label: "Taxa",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return `${value}`.replace('.',',') || 0
            },
          },
        },

        {
          name: "credit_limit",
          label: "Limite de Crédito",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return this.ccyFormat(value || 0);
            },
          },
        },

        {
          name: "used_credit_limit",
          label: "Limite de Crédito Usado",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return this.ccyFormat(value || 0);
            },
          },
        },

        {
          name: "remaining_credit_limit",
          label: "Limite de Crédito Restante",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return this.ccyFormat(value || 0);
            },
          },
        },

        {
          name: "actions",
          label: "Ações",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div style={{ width: "40%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <EditIcon
                      onClick={(e) => {
                        this.setState({
                          editIdClient: tableMeta.rowData[0],
                          open: true,
                        });
                      }}
                    />
                    <DeleteIcon
                      onClick={() =>
                        this.setState({
                          openModalDelete: true,
                          idClientToExclude: tableMeta.rowData[0],
                        })
                      }
                    />
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.editIdClient !== this.state.editIdClient &&
      this.state.editIdClient
    ) {
      const { data } = await this.getClientById(this.state.editIdClient);

      this.setState({ name: data.nome, rate: data.taxa, credit_limit: data.limite_credito || '' });
    }
  }

  async componentDidMount() {
    const data = await this.fetchData();

    this.setState({
      data: data.data.data,
      totalCLients: data.data.totalClients.clients,
      loading: false,
    });
  }

  async getClientById(id) {
    const API = process.env.REACT_APP_API;
    const { data } = await instance().get(`${API}/client/${id}`);

    return data;
  }

  async fetchData() {
    this.setState({ loading: true });
    const API = process.env.REACT_APP_API;

    return await instance().get(`${API}/clients`);
  }

  async fetchSearchData(searchText) {
    const count = this.state.count; // limit
    const page = this.state.page; // page
    const offset = this.state.offset + count * page; //offset

    let { searchTimeout } = this.state;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (searchText && searchText.length > 0) {
      this.setState({ searchText: searchText });
      
      searchTimeout = setTimeout(async () => {
        const apiUrl = this.buildUrlParams({ count : count, offset : offset, searchText : searchText });
        const clients = await instance().get(apiUrl);

        // totalCLients: data.data.totalClients.clients,
        this.setState({
          data: clients.data.data,
          dataFilter: clients.data.data,
          totalCLients: clients.data.totalClients.clients
        });
      }, 500);

      this.setState({ searchTimeout: searchTimeout });
    } else {

      const apiUrl = this.buildUrlParams({ count : count, offset : offset });
      const clients = await instance().get(apiUrl);

      this.setState({ data: clients.data.data, dataFilter: [], isSearch: false, searchText: "" });
    }
  }

  async handleDelete() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const { idClientToExclude } = this.state;

    const newDataList = this.state.data.filter(
      (item) => item.id !== idClientToExclude
    );

    if (idClientToExclude) {
      try {
        const removeClient = await instance().delete(
          `${API}/person/${idClientToExclude}`
        );

        if (removeClient) {
          this.setState({
            data: newDataList,
            openModalDelete: false,
            loadingButton: false,
            totalCLients: this.state.totalClients - 1,
          });
        }
      } catch (err) {
        if (err.status === 400) {
          this.setState({ loadingButton: false });
          return alert(
            "Você não pode deletar este usuario, pois há vinculo com alguma operação."
          );
        }
        console.log(err);
      }
    }

    window.location.reload();
  }

  async updateClient() {
    const { name, rate, credit_limit, editIdClient } = this.state;
    const API = process.env.REACT_APP_API;

    const { data } = await instance().put(`${API}/client/${editIdClient}`, {
      name: name.toLocaleUpperCase(),
      credit_limit: credit_limit,
      rate: rate,
    });

    return data;
  }

  async createClient() {
    const API = process.env.REACT_APP_API;

    const { name, rate, credit_limit } = this.state;

    const { data } = await instance().post(`${API}/client`, {
      name: name.toLocaleUpperCase(),
      rate: rate,
      credit_limit: credit_limit
    });

    return data.idClient;
  }

  handleChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (name) => {
    this.setState({ [name]: false });
  };

  errorParams() {
    const { name, rate, credit_limit } = this.state;
    let params = [];
    let message = "Por favor, insira o(s) campo(s):";

    if (!name) {
      params.push(" nome");
    }
    if (!rate) {
      params.push(" taxa");
    }

    if (!credit_limit) {
      params.push(" Limite de Crédito");
    }

    if (params.length) {
      this.setState({ loadingButton: false });

      return (message += params);
    }
  }

  handleSave = async () => {
    this.setState({ loadingButton: true });

    const { editIdClient } = this.state;

    if (this.errorParams()) {
      return alert(this.errorParams());
    }
    const isEdit = !!editIdClient;

    if (isEdit) {
      await this.updateClient();

      const newDataList = this.state.data.map((item) => {
        if (item.id === editIdClient) {
          return {
            id: item.id,
            name: this.state.name,
            credit_limit: this.state.credit_limit,
            rate: this.state.rate + "%",
          };
        }
        return { ...item };
      });

      this.setState({
        open: false,
        data: newDataList,
        loadingButton: false,
      });
    } else {
      const clientId = await this.createClient();

      if (clientId) {
        const newValue = {
          id: clientId,
          name: this.state.name,
          credit_limit: this.state.credit_limit,
          rate: this.state.rate + "%",
        };

        this.setState({
          data: [...this.state.data, newValue],
          name: "",
          rate: "",
          credit_limit: "",
          open: false,
          totalCLients: this.state.totalCLients + 1,
          loadingButton: false,
        });
      }
    }

    window.location.reload();
  };

  changePage = async (page) => {
    const { count, offset, searchText } = this.state;
    const API = process.env.REACT_APP_API;

    const newOffset = offset + count * page;
    const apiUrl = `${API}/clients?limit=${count}&offset=${newOffset}${searchText ? `&filter=${searchText}` : ''}`;

    try {
      const response = await instance().get(apiUrl);

      const newState = {
        count,
        data: response.data.data,
        dataFilter: searchText ? response.data.data : [],
        isSearch: false,
        searchText: searchText || "",
      };

      this.setState(newState);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  changeSearch = async (searchText) => {
    this.fetchSearchData(searchText);
  };
  

  handleChangeRowsPerPage = async (value) => {
    const count = value; // limit
    const offset = this.state.offset; //offset
    const { searchText } = this.state;

    const apiUrl = this.buildUrlParams({ count : count, offset : offset, searchText : searchText });
    // let apiUrl = `${API}/clients?limit=${count}&offset=${offset}`;
    // if (searchText) {
    //   apiUrl = `${API}/clients?limit=${count}&offset=${offset}&filter=${searchText}`;
    // }

    const clients = await instance().get(apiUrl);

    let state = { count: value, data: clients.data.data, dataFilter: [], isSearch: false, searchText: "" };
    if (searchText) {
      state = { count: value, data: clients.data.data, dataFilter: clients.data.data };
    }
    this.setState(state);
  };

  buildUrlParams( params ) {
    const API = process.env.REACT_APP_API;

    const { count, offset, searchText } = params;
    const queryParams = [];
  
    if (count !== undefined) {
      queryParams.push(`limit=${count}`);
    }
  
    if (offset !== undefined) {
      queryParams.push(`offset=${offset}`);
    }
  
    if (searchText !== undefined) {
      queryParams.push(`filter=${searchText}`);
    }
  
    if (this.state.sortBy !== undefined) {
      queryParams.push(`sort=${this.state.sortBy}`);
    }
  
    if (this.state.sortDirection !== undefined) {
      queryParams.push(`sort_direction=${this.state.sortDirection}`);
    }
    const queryString = queryParams.join("&");
  
    return `${API}/clients${queryString ? `?${queryString}` : ''}`;
  }

  ccyFormat(num) {
    return `R$${num
      .toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      .replace(".", ",")
      .replace(",", ".")}`;
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      name,
      rate,
      credit_limit,
      data,
      columns,
      loading,
      totalCLients,
      page,
    } = this.state;

    const inputProps = {
      step: 0.1,
    };

    return (
      <div>
        <div className={classes.content}>
          <React.Fragment>
            {loading ? (
              <div className={classes.loading}>
                <Loading size={40} />
              </div>
            ) : (
              <Table
                page={page}
                rowsPerPage={this.state.count}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                searchText={this.state.searchText}
                onTableChange={async (action, tableState) => {
                  switch (action) {
                    case "changePage":
                      this.changePage(tableState.page);
                      break;

                    case "search":
                      this.setState({
                        isSearch: true,
                      });
                      this.changeSearch(tableState.searchText);
                      break;
                    case "onSearchClose":
                      this.setState({
                        isSearch: false,
                        searchText: "",
                      });
                      this.changePage(tableState.page);
                      break;
                    case "sort":
                      const sortState = tableState.columns.find((f) => f.sortDirection !== 'none');
                      this.setState({
                        sortBy: sortState.name,
                        sortDirection: sortState.sortDirection,
                      }, () => this.fetchSearchData());
                      
                      break;
                    default:
                      break;
                  }
                }}
                count={totalCLients}
                titleText="Clientes"
                data={
                  this.state.isSearch === true
                    ? this.state.dataFilter
                    : this.state.data
                }
                columns={columns}
              >
                <Button
                  onClick={() =>
                    this.setState({
                      open: true,
                      editIdClient: 0,
                      rate: "",
                      name: "",
                      credit_limit: "",
                    })
                  }
                  className={classes.button}
                >
                  <AddIcon className={classes.addIcon} fontSize="large" />
                </Button>
              </Table>
            )}
          </React.Fragment>
        </div>

        <Dialog
          onClose={() => this.handleClose("open")}
          type="client"
          open={open}
          title={
            this.state.editIdClient ? "Editar cliente" : "Adicionar cliente"
          }
        >
          <div className={classes.dialogContent}>
            <TextField
              value={name}
              onChange={this.handleChange("name")}
              className={classes.textField}
              required
              label="Nome"
            />

            <TextField
              value={rate}
              onChange={this.handleChange("rate")}
              className={classes.textField}
              required
              type="number"
              inputProps={inputProps}
              label="Taxa Mínima (%)"
            />

            <CurrencyTextField
              label="Limite"
              variant="standard"
              value={credit_limit}
              currencySymbol="R$"
              decimalCharacter=","
              digitGroupSeparator="."
              outputFormat="string"
              onChange={(e, value) => this.setState({ credit_limit: value })}
              required
              className={classes.textField}
            />
          </div>
          <Button onClick={this.handleSave} autoFocus color="primary">
            {this.state.loadingButton ? (
              <div className={classes.loading}>
                <Loading size={40} />
              </div>
            ) : this.state.editIdClient ? (
              "Editar"
            ) : (
              "Cadastrar"
            )}
          </Button>
        </Dialog>

        <ModalDelete
          name="cliente"
          message="Você tem certeza que deseja excluir este cliente ?"
          open={this.state.openModalDelete}
          handleClose={() => this.handleClose("openModalDelete")}
          handleDelete={() => this.handleDelete()}
          loading={this.state.loadingButton}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  addIcon: {
    color: "#ffff",
  },
  button: {
    backgroundColor: "#4caf50",
    width: 50,
    marginBottom: 8,
    marginTop: 8,
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 48,
    marginRight: 48,
    marginLeft: 48,
  },
  textField: {
    margin: 18,
  },
  loading: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

ClientScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClientScreen);
