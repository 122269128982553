import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Loading from "@material-ui/core/CircularProgress";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Excluir {props.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            {props.loading ? (
              <div
                style={{
                  marginRight: 24,
                }}
              >
                <Loading size={40} />
              </div>
            ) : (
              <React.Fragment>
                <Button autoFocus onClick={props.handleClose} color="primary">
                  Cancelar
                </Button>
                <Button onClick={props.handleDelete} color="primary">
                  Excluir
                </Button>
              </React.Fragment>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
