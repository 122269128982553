import React from "react";

const { Provider, Consumer } = React.createContext();

export class UIProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastDueDate: "",
      setLastDueDate: (lastDueDate) => this.setState({ lastDueDate }),
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export const withUIContext = (Component) => (props) => (
  <UIConsumer>
    {(context) => <Component {...props} uiContext={context} />}
  </UIConsumer>
);

export const UIConsumer = Consumer;
