import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    width: 150,
    textAlign: "center",
    backgroundColor: "#ffff",
    border: "3px solid #3f51b5",
    paddingTop: "10px",
    margin: 24,
  },
  cardContent: {
    paddingLeft: 5,
    paddingRight: 5,
    textTransform: "uppercase"
  },
  body: {
    fontWeight: 600,
    color: "black"
  }
};

export const CardItem = props => {
  const { classes } = props;
  const Icon = props.Icon;

  return (
    <div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Icon />
          <Typography className={classes.body} component="p">
            {props.name}
          </Typography>
        </CardContent>
        <CardActions />
      </Card>
    </div>
  );
};

CardItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardItem);
