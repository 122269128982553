import React from "react";
import { Router as ReachRouter } from "@reach/router";
import HomeScrenn from "./../screens/HomeScreen";
import ClientScreen from "./../screens/ClientScreen";
import EmitterScreen from "./../screens/EmitterScreen";
import OperationScreen from "./../screens/OperationScreen";
import ListBankChecks from "./../screens/ListBankChecks";
import ReportScreen from "./../screens/ReportScreen";
import LoginScreen from "./../screens/LoginScreen";
import ComponentToPrint from "./../components/ComponentPrint";
import ReportBankChecks from "./../screens/ReportScreen/SearchBankCheck";
import ReportOperations from "./../screens/ReportScreen/Operations";
import ReportCheksToBeCached from "./../screens/ReportScreen/ChecksToBeCached";
import ReportCheksToBeCachedByClient from "./../screens/ReportScreen/ChecksToBeCachedByClient";

export const Router = () => (
  <ReachRouter>
    <HomeScrenn path="/inicio" />
    <ClientScreen path="/cliente" />
    <EmitterScreen path="/emitente" />
    <OperationScreen path="/operacao" />
    <ReportScreen path="/relatorios" />
    <ReportCheksToBeCached path="/relatorio/cheques-a-descontar" />
    <ReportCheksToBeCachedByClient path="/relatorio/cheques-a-descontar-por-cliente" />

    <ReportBankChecks path="/relatorio/pesquisa-cheque" />
    <ReportOperations path="/relatorio/operacoes" />

    <ListBankChecks path="operacao/:id/cheques" />
    <ComponentToPrint path="/impressao/:idOperation/:clearAmount/:clientName/:totalValue/:amountPayable/:currentDate/:currentDateFormated/" />
  </ReachRouter>
);

export const RouterLogin = () => (
  <ReachRouter>
    <LoginScreen path="/" />
  </ReachRouter>
);
