import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Title from "./../../../components/Title";
import { InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import Loading from "@material-ui/core/CircularProgress";
import { instance } from "./../../../config";
import CancelIcon from '@material-ui/icons/Cancel';
class ReportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: Moment().subtract(3, "days").format("DD-MM-YYYY"),
      endDate: Moment().add(3, "days").format("DD-MM-YYYY"),
      sort: "Vencimento Desc",
      filter: "day",
      dayReports: [],
      weekReports: [],
      clientId: null,
      clientName: '',
      totalValue: 0,
      isLoading: false,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.startDate !== this.state.startDate && this.state.startDate) ||
      (prevState.endDate !== this.state.endDate && this.state.endDate)
    ) {
      if (this.state.filter === "day") {
        this.fetchDataDay();
      } else if (this.state.filter === "week") {
        this.fetchDataWeek();
      }
    }
  }

  async componentDidMount() {
    const { state } = this.props.location;
    const { filter, startDate, endDate } = state || {};
      
    if (filter && startDate && endDate) {
      const transformedStartDate =  Moment(startDate, 'DD/MM/YYYY').format('DD-MM-YYYY');
      const transformedEndDate =  Moment(endDate, 'DD/MM/YYYY').format('DD-MM-YYYY');

      this.setState({ filter, startDate: transformedStartDate, endDate: transformedEndDate }, () => {
        this.fetchReport();
      });
    }
  }
  

  async fetchDataDay() {
    this.setState({ isLoading: true });
    const API = process.env.REACT_APP_API;

    const params = {
      initialDate: this.state.startDate,
      finalDate: this.state.endDate,
    };
    
    if (this.state.clientId) {
      params.client = this.state.clientId;
    }
    
    const apiUrl = `${API}/reports/totalClientOperation/day?sort=${this.state.sort}`;
    
    try {
      const report = await instance().get(apiUrl, { params });

      this.setState({
        dayReports: report.data.data,
        totalValue: report.data.totalValue,
        isLoading: false,
      });
    } catch (error) {
      alert(error);
    }
  }

  fetchReport = () => {
    const { filter } = this.state;

    if (filter === "day") {
      this.fetchDataDay();
    } else if (filter === "week") {
      this.fetchDataWeek();
    }
  }

  handleChangeSort = (e) => {
    this.setState({sort : e.target.value}, () => this.fetchReport());
  }

  async fetchDataWeek() {
    this.setState({ isLoading: true });
    const API = process.env.REACT_APP_API;
    const params = {
      initialDate: this.state.startDate,
      finalDate: this.state.endDate,
    };
  
    if (this.state.clientId) {
      params.client = this.state.clientId;
    }
    
    const apiUrl = `${API}/reports/totalClientOperation/week?sort=${this.state.sort}`;
  
    try {
      const report = await instance().get(apiUrl, { params });
      this.setState({
        weekReports: report.data.data,
        totalValue: report.data.totalValue,
        isLoading: false,
      });
    } catch (error) {
      alert(error);
    }
  }
  

  handleFilter = (e) => {
    this.setState({ loading: true, filter: e.target.value });
    const filter = e.target.value;
    if (filter === "day") {
      this.fetchDataDay();
    }
    if (filter === "week") {
      this.fetchDataWeek();
    }

    this.setState({ loading: false });
  };


  handleClientDetail = (row) => {
    this.setState({ clientId: row.client_id, clientName : row.client }, () => {
      if (this.state.filter === "day") {
        this.fetchDataDay();
      } else if (this.state.filter === "week") {
        this.fetchDataWeek();
      }
    });
  };

  handleClearDetail = row => {
    this.setState({ clientId: null, clientName : null }, () => {
      if (this.state.filter === "day") {
        this.fetchDataDay();
      } else if (this.state.filter === "week") {
        this.fetchDataWeek();
      }
    });
  }

  renderReportTable(dayReports, totalValue) {
    const { classes } = this.props;
    const { filter, weekReports } = this.state;

    function ccyFormat(num) {
      if (!num) {
        return 0;
      }

      return `R$${num
        .toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .replace(".", ",")
        .replace(",", ".")}`;
    }

    if (filter === "day") {
      if (!dayReports.length) {
        return (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 64,
            }}
          >
            <Typography>Você não possui registros</Typography>
          </Grid>
        );
      } else {
        return (
          <TableContainer className={classes.table} component={Paper}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Detalhes
                    { (this.state.clientId) ? 
                    <span> para cliente {this.state.clientName} <CancelIcon onClick={() => this.handleClearDetail()}></CancelIcon> </span> : 
                    ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Semana</TableCell>
                  <TableCell>Cliente</TableCell>

                  { (this.state.clientId) ? 
                    <TableCell>Emitente</TableCell>: 
                  ''}

                  <TableCell align="right">Dia</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dayReports &&
                  dayReports.map((row, index) => (
                    <TableRow key={index} onClick={() => this.handleClientDetail(row)}>
                    <TableCell><u>{row.week}</u></TableCell>
                    <TableCell><u>{row.client}</u></TableCell>

                  { (this.state.clientId) ? 
                    <TableCell><u>{row.emitente}</u></TableCell>: 
                  ''}
                    <TableCell align="right">{row.dueDate}</TableCell>
                    <TableCell align="right">{ccyFormat(row.total)}</TableCell>
                  </TableRow>
                  ))}

                <TableRow>
                  <TableCell rowSpan={3} />

                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                    {ccyFormat(totalValue && totalValue)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    }

    if (filter === "week") {
      if (!weekReports.length) {
        return (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 64,
            }}
          >
            <Typography>Você não possui registros</Typography>
          </Grid>
        );
      } else {
        return (
          <TableContainer className={classes.table} component={Paper}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Detalhes
                    { (this.state.clientId) ? 
                    <span> para cliente {this.state.clientName} <CancelIcon onClick={() => this.handleClearDetail()}></CancelIcon> </span> : 
                    ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Semana</TableCell>
                  <TableCell align="right">Inicial</TableCell>
                  <TableCell align="right">Cliente</TableCell>

                  { (this.state.clientId) ? 
                    <TableCell>Emitente</TableCell>: 
                  ''}

                  <TableCell align="right">Final</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {weekReports &&
                  weekReports.map((row, index) => (
                    <TableRow key={index}  onClick={() => this.handleClientDetail(row)}>
                      <TableCell><u>{row.week}</u></TableCell>
                      <TableCell align="right"><u>{row.client}</u></TableCell>

                      { (this.state.clientId) ? 
                        <TableCell><u>{row.emitente}</u></TableCell>: 
                      ''}                      
                      <TableCell align="right"><u>{row.initial}</u></TableCell>
                      <TableCell align="right"><u>{row.final}</u></TableCell>
                      <TableCell align="right"><u>{ccyFormat(row.total && row.total)}</u></TableCell>
                    </TableRow>
                  ))}

                <TableRow>
                  <TableCell rowSpan={3} />

                  <TableCell rowSpan={3} />
                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                    {ccyFormat(totalValue && totalValue)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      startDate,
      endDate,
      dayReports,
      totalValue,
      isLoading,
      filter,
    } = this.state;

    return (
      <div className={classes.content}>
        <Grid style={{ display: "flex", justifyContent: "center" }}>
          <Title text="Cheques a descontar por Cliente" />
        </Grid>
        <Grid
          style={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
        >
          <FormControl style={{ marginBottom: 24 }} component="fieldset">
            <RadioGroup
              value={this.state.filter}
              style={{ display: "flex", flexDirection: "row" }}
              onChange={this.handleFilter}
            >
              <FormControlLabel
                value="week"
                control={<Radio color="primary" />}
                label="por semana"
              />
              <FormControlLabel
                value="day"
                style={{ marginLeft: 8 }}
                control={<Radio color="primary" />}
                label="por dia"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                id="date"
                label="Inicial"
                type="date"
                value={Moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                onChange={(e) =>
                  this.setState({
                    startDate: Moment(e.target.value).format("DD/MM/YYYY"),
                  })
                }
                defaultValue={startDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="date"
              label="Final"
              type="date"
              value={Moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
              onChange={(e) =>
                this.setState({
                  endDate: Moment(e.target.value).format("DD/MM/YYYY"),
                })
              }
              defaultValue={endDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid container spacing={2} justify="center">
            <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <FormControl style={{ minWidth: "200px", alignSelf: "center" }}>
                <InputLabel id="sortorder-label">Ordenar Por</InputLabel>
                <Select
                  labelId="sortorder-label"
                  id="sortorder"
                  value={this.state.sort}
                  label="Ordernar Por"
                  onChange={(e) => this.handleChangeSort(e)}
                >
                <MenuItem value={'vencimento asc'}>Data de Vencimento (Crescente)</MenuItem>
                  <MenuItem value={'vencimento desc'}>Data de Vencimento (Decrescente)</MenuItem>
                  <MenuItem value={'client asc'}>Cliente</MenuItem>
                  <MenuItem value={'total asc'}>Valor crescente</MenuItem>
                  <MenuItem value={'total desc'}>Valor decrescente</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {isLoading ? (
          <div className={classes.loading}>
            <Loading size={40} />
          </div>
        ) : (
          this.renderReportTable(dayReports, totalValue)
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  loading: {
    marginTop: 36,
    display: "flex",
    justifyContent: "center",
  },
  table: {
    marginTop: 64,
    display: "flex",
    alignSelf: "center",
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
});

export default withStyles(styles)(ReportScreen);
