import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Typography,
} from "@material-ui/core";
import { instance } from "./../../../config";
import Title from "./../../../components/Title";
import Moment from "moment";
import Loading from "@material-ui/core/CircularProgress";

class ReportOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: Moment().subtract(10, "days").format("DD-MM-YYYY"),
      endDate: Moment().add(10, "days").format("DD-MM-YYYY"),
      data: [],
      totalValueBankCheks: "",
      totalAmountPayable: "",
      totalClearAmount: "",
      isLoading: false,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.startDate !== this.state.startDate && this.state.startDate) ||
      (prevState.endDate !== this.state.endDate && this.state.endDate)
    ) {
      await this.fetchData();
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    this.setState({ isLoading: true });
    const API = process.env.REACT_APP_API;
    const { startDate, endDate } = this.state;

    const data = await instance().get(
      `${API}/reports/operations?initialDate=${startDate}&finalDate=${endDate}`
    );

    if (data) {
      const totalValueBankCheks = data.data.data.reduce((previous, acc) => {
        return previous + parseFloat(acc.totalValue);
      }, 0);

      const totalAmountPayable = data.data.data.reduce((previous, acc) => {
        return previous + acc.amount_payable;
      }, 0);

      const totalClearAmount =
        parseFloat(totalValueBankCheks) - parseFloat(totalAmountPayable);
      this.setState({
        data: data.data.data,
        totalValueBankCheks: totalValueBankCheks,
        totalAmountPayable: totalAmountPayable,
        totalClearAmount: totalClearAmount,
        isLoading: false,
      });
    }
  }
  renderTable() {
    const {
      data,
      totalValueBankCheks,
      totalAmountPayable,
      totalClearAmount,
    } = this.state;
    const { classes } = this.props;

    function ccyFormat(num) {
      return `R$ ${num.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}`;
    }

    if (!data.length) {
      return (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 64,
          }}
        >
          <Typography>Você não possui registros</Typography>
        </Grid>
      );
    }
    return (
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Detalhes
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data operação</TableCell>
              <TableCell align="center">Código operação</TableCell>
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Cheques</TableCell>
              <TableCell align="center">Desembolso</TableCell>
              <TableCell align="center">Diferencial</TableCell>
              <TableCell align="center">Margem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.dateOperation}</TableCell>
                  <TableCell align="center">{row.idOperation}</TableCell>
                  <TableCell align="center">{row.clientName}</TableCell>
                  <TableCell align="center">
                    {row.totalValue && ccyFormat(row.totalValue)}
                  </TableCell>
                  <TableCell align="center">
                    {(row.amount_payable && ccyFormat(row.amount_payable)) || 0}
                  </TableCell>
                  <TableCell align="center">
                    {row.clear_amount && ccyFormat(row.clear_amount)}
                  </TableCell>
                  <TableCell align="center">
                    {row.effective_rate_month && row.effective_rate_month + "%"}
                  </TableCell>
                </TableRow>
              ))}

            <TableRow>
              <TableCell />

              <TableCell />
              <TableCell align="center"> </TableCell>
              <TableCell align="center">
                {totalValueBankCheks &&  ccyFormat(totalValueBankCheks)}
              </TableCell>
              <TableCell align="center">
                {totalAmountPayable && ccyFormat(totalAmountPayable)}
              </TableCell>
              <TableCell align="center">
                {totalClearAmount && ccyFormat(totalClearAmount)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const { classes } = this.props;
    const { startDate, endDate, isLoading } = this.state;

    return (
      <div className={classes.content}>
        <Title text="Relatórios de operações" />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                id="date"
                label="Inicial"
                type="date"
                value={Moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                onChange={(e) =>
                  this.setState({
                    startDate: Moment(e.target.value).format("DD/MM/YYYY"),
                  })
                }
                defaultValue={startDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="date"
              label="Final"
              type="date"
              value={Moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
              onChange={(e) =>
                this.setState({
                  endDate: Moment(e.target.value).format("DD/MM/YYYY"),
                })
              }
              defaultValue={endDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <div className={classes.loading}>
            <Loading size={40} />
          </div>
        ) : (
          this.renderTable()
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  loading: {
    marginTop: 36,
    display: "flex",
    justifyContent: "center",
  },
  table: {
    marginTop: 64,
    display: "flex",
    alignSelf: "center",
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
});
export default withStyles(styles)(ReportOperations);
