import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "@reach/router";
import CardItem from "./CardHome";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BarChartIcon from "@material-ui/icons/BarChart";

const styles = theme => ({
  sizeIconCard: {
    fontSize: 60,
    fill: "#3f51b5"
  },
  root: {
    flexGrow: 1
  },
  paper: {
    color: theme.palette.text.secondary
  },
  grid: {
    justifyContent: "center"
  }
});

export const ScreenHome = props => {
  const { classes } = props;

  const menu = [
    {
      icon: <PersonIcon className={classes.sizeIconCard} />,
      text: "Cliente",
      path: "/cliente"
    },
    {
      icon: <BusinessIcon className={classes.sizeIconCard} />,
      text: "Emitente",
      path: "/emitente"
    },
    {
      icon: <AttachMoneyIcon className={classes.sizeIconCard} />,
      text: "Operacao",
      path: "/operacao"
    },

    {
      icon: <BarChartIcon className={classes.sizeIconCard} />,
      text: "Relatorios",
      path: "/relatorios"
    }
  ];
  return (
    <Grid className={classes.grid} container>
      {menu.map((item, index) => {
        return (
          <Grid item key={index}>
            <Link to={item.path} style={{ textDecoration: "none" }}>
              <Paper
                component={() => (
                  <CardItem name={item.text} Icon={() => item.icon} />
                )}
                className={classes.paper}
              />
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};

ScreenHome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ScreenHome);
