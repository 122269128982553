import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "./../../components/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@mui/material/Autocomplete';
import Table from "./../../components/Table";
import Loading from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import BancCheckIcon from "@material-ui/icons/SubtitlesTwoTone";
import { Link } from "@reach/router";
import ModalDelete from "./../../components/ModalDelete";
import Moment from "moment";
import { instance } from "./../../config";
import { Card, CardContent } from "@material-ui/core";

class OperationScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      loadingButton: false,
      dataFilter: [],
      isSearch: false,
      searchText: "",
      searchTimeout: null,
      operationIdEdit: 0,
      idOperationToExclude: 0,
      page: 0,
      count: 10,
      offset: 0,
      totalOperations: 0,
      openModalDelete: false,
      idClient: "",
      name: "",
      date: "",
      clients: [],
      rate: "",
      data: [],
      selectedClient: null,
      sort: "operacao.codigo desc",

      isValidRate: true,

      columns: [
        {
          name: "id",
          label: "Codigo",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "clientName",
          label: "Nome",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "date",
          label: "Data",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "interest",
          label: "Juro",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return `${value}`.replace('.',',') || 0
            },
          },
        },
        {
          name: "actions",
          label: "Ações",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div style={{ width: "40%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <EditIcon
                      style={{ marginRight: 8 }}

                      onClick={(e) => {
                        this.setState({
                          operationIdEdit: tableMeta.rowData[0],
                          open: true,
                        });
                      }}
                    />
                    <DeleteIcon
                      style={{ marginRight: 8 }}
                      onClick={() =>
                        this.setState({
                          openModalDelete: true,
                          idOperationToExclude: tableMeta.rowData[0],
                        })
                      }
                    />
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to={`${tableMeta.rowData[0]}/cheques`}
                    >
                      <BancCheckIcon />
                    </Link>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.operationIdEdit !== this.state.operationIdEdit &&
      this.state.operationIdEdit
    ) {
      const { data } = await this.getOperationById(this.state.operationIdEdit);

      const editingClient = this.state.clients.find(
        (client) => client.id === data.id_client
      );

      this.setState({
        idClient: data.id_client,
        selectedClient: editingClient,
        date: Moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        rate: data.interest.replace("%", ""),
      });
    }
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    // this.setState({ loading: true });
    const API = process.env.REACT_APP_API;

    const clients = await instance().get(`${API}/clients/all`);
    const operations = await instance().get(`${API}/operations?sort=${this.state.sort}`);

    this.setState({
      clients: clients.data && clients.data.data,
      data: operations.data && operations.data.data,
      totalOperations:
        operations.data && operations.data.totalOperations.operations,
      loading: false,
    });
  }

  async createOperation() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const { idClient, date, rate } = this.state;

    const { data } = await instance().post(`${API}/operation`, {
      id_client: idClient,
      date,
      rate: rate,
    });

    return data.idOperation;
  }

  async updateOperation() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const { idClient, date, operationIdEdit, rate } = this.state;

    const { data } = await instance().put(
      `${API}/operation/${operationIdEdit}`,
      {
        idClient,
        date,
        rate,
      }
    );

    return data;
  }

  async getOperationById(id) {
    const API = process.env.REACT_APP_API;

    const { data } = await instance().get(`${API}/operation/${id}`);

    return data;
  }

  errorParams() {
    const { idClient, date, rate } = this.state;
    let params = [];
    let message = "Por favor, insira o(s) campo(s):";

    if (!idClient) {
      params.push(" cliente");
    }
    if (!date) {
      params.push(" data");
    }
    if (!rate) {
      params.push(" juro");
    }

    if (params.length) {
      return (message += params);
    }
  }

  handleSave = async () => {
    const { operationIdEdit } = this.state;

    if (this.errorParams()) {
      return alert(this.errorParams());
    }
    const isEdit = !!operationIdEdit;

    if (isEdit) {
      await this.updateOperation();

      const filterClient = this.state.clients.filter(
        (client) => client.id === this.state.idClient
      );

      const newDataList = this.state.data.map((item) => {
        if (item.id === operationIdEdit) {
          return {
            id: item.id,
            clientName: filterClient[0].name,
            date: Moment(this.state.date).format("DD/MM/YYYY"),
            interest: this.state.rate + "%",
          };
        }
        return { ...item };
      });

      this.setState({
        open: false,

        data: newDataList,
        loadingButton: false,
      });
    } else {
      const operationId = await this.createOperation();

      const filterClient = this.state.clients.filter(
        (client) => client.id === this.state.idClient
      );

      const newValue = {
        id: operationId,
        clientName: filterClient[0].name,
        interest: this.state.rate + "%",
        date: Moment(this.state.date).format("DD/MM/YYYY"),
      };
      this.setState({
        data: [newValue, ...this.state.data],
        idClient: "",
        date: "",
        open: false,
        loadingButton: false,
      });
    }
  };

  async handleDelete() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const { idOperationToExclude } = this.state;

    const newDataList = this.state.data.filter(
      (item) => item.id !== idOperationToExclude
    );
    if (idOperationToExclude) {
      const removeOperation = await instance().delete(
        `${API}/operation/${idOperationToExclude}`
      );

      if (removeOperation) {
        this.setState({
          data: newDataList,
          openModalDelete: false,
          loadingButton: false,
          totalOperations: this.state.totalOperations - 1,
        });
      }
    }
  }

  handleClose = (name) => {
    this.setState({ [name]: false });
  };

  changePage = async (page) => {
    const API = process.env.REACT_APP_API;
    const count = this.state.count; // limit
    const offset = this.state.offset + count * page; //offset

    const operations = await instance().get(
      `${API}/operations?limit=${count}&offset=${offset}&sort=${this.state.sort}`
    );

    this.setState({ page, data: operations.data.data });
  };

  changeSearch = async (searchText) => {
    function removeAccents(s) {
      return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const filter =
      this.state.data &&
      this.state.data.filter(
        (item) =>
          removeAccents(
            escape(item.clientName && item.clientName.toLowerCase())
          ).search(
            removeAccents(escape(searchText && searchText.toLowerCase()))
          ) !== -1
      );

    this.setState({
      searchText: searchText,
      dataFilter: searchText && searchText.length ? filter : this.state.data,
    });
  };

  handleChangeRowsPerPage = async (value) => {
    const API = process.env.REACT_APP_API;
    const count = value; // limit
    const offset = this.state.offset; //offset

    const operations = await instance().get(
      `${API}/operations?limit=${count}&offset=${offset}&sort=${this.state.sort}`
    );

    this.setState({ count: value, data: operations.data.data });
  };

  handleClientChange = (event, newValue) => {
    if (newValue && newValue.id) {
      this.setState({
        idClient: newValue.id,
        selectedClient: newValue,
      });
    } else {
      this.setState({
        idClient:"",
        selectedClient: null,
      });
    }
  };

  handleRateChange = (e) => {
    const newRate = parseFloat(e.target.value);
    const clientRate = parseFloat(this.state.selectedClient.rate);
  
    if (newRate < clientRate) {

      this.setState({isValidRate: false});
      // alert(`Taxa menor que a parametrizada para este cliente ${this.state.selectedClient.rate}`);
       // e.target.value = this.state.rate; // Reset the input value to the previous rate
    } else {
      this.setState({ rate: newRate, isValidRate: true });
    }
  }

  ccyFormat(num) {
    return `R$${num
      .toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      .replace(".", ",")
      .replace(",", ".")}`;
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      date,
      loading,
      data,
      columns,
      clients,
      operationIdEdit,
      page,
      count,
      searchText,
      totalOperations,
    } = this.state;

    return (
      <div>
        <div className={classes.content}>
          {loading ? (
            <div className={classes.loading}>
              <Loading size={40} />
            </div>
          ) : (
            <Table
              page={page}
              rowsPerPage={count}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              searchText={searchText}
              onTableChange={async (action, tableState) => {
                switch (action) {
                  case "changePage":
                    this.changePage(tableState.page);
                    break;
                  case "search":
                    this.setState({
                      isSearch: true,
                    });
                    this.changeSearch(tableState.searchText);
                    break;
                  case "onSearchClose":
                    this.setState({
                      searchText: "",
                      isSearch: false,
                    });
                    this.changePage(tableState.page);
                    break;
                  case "sort":
                    const sortState = tableState.columns.find((f) => f.sortDirection !== 'none');

                    this.setState({
                      sort: `${sortState.name} ${sortState.sortDirection}`,
                    }, () => this.fetchData());
                    break;
                  default:
                    break;
                }
              }}
              count={totalOperations}
              titleText="Operações"
              data={
                this.state.isSearch === true
                  ? this.state.dataFilter
                  : this.state.data
              }
              columns={columns}
            >
              <Button
                onClick={() =>
                  this.setState({
                    open: true,
                    operationIdEdit: 0,
                    selectedClient: {},
                    idClient: 0,
                    date: "",
                    rate: "",
                  })
                }
                className={classes.button}
              >
                <AddIcon className={classes.addIcon} fontSize="large" />
              </Button>
            </Table>
          )}
        </div>

        <Dialog
          onClose={() => this.handleClose("open")}
          type="client"
          open={this.state.open}
          title={
            this.state.operationIdEdit
              ? "Editar operação"
              : "Adicionar operação"
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 48,
              marginRight: 48,
              marginLeft: 48,
            }}
          >
            <FormControl style={{ margin: 12 }}>
              <Autocomplete
                options={clients}
                getOptionLabel={(client) => (client && client.name) ? `${client.id} - ${client.name}` : 'Buscar Cliente...'}
                value={this.state.selectedClient}
                onChange={this.handleClientChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    variant="outlined"
                    style={{ minHeight: '50px' }}
                  />
                )}
              />

              {this.state.idClient > 0 && (
                <TextField
                  value={this.state.rate}
                  onBlur={this.handleRateChange}
                  onChange={(e) => this.setState({ rate: e.target.value })}
                  style={{ margin: 18 }}
                  required
                  type="number"
                  inputProps={{ step: 0.1 }}
                  label="Juro (porcentagem)"
                />
              )}

              {!this.state.isValidRate && (
                  <span style={{color: 'red', fontWeight: 'bold'}}>Taxa menor que a parametrizada para este cliente: {this.state.selectedClient.rate}</span>
              )}
              
              <TextField
                id="date"
                label="Data"
                type="date"
                value={date}
                defaultValue="2017-05-24"
                onChange={(e) => this.setState({ date: e.target.value })}
                style={{ margin: 18 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Card>
                <CardContent>
                  <table>
                    <tbody>
                    {this.state.selectedClient && this.state.selectedClient.rate ? (
                      <React.Fragment>
                        <tr>
                          <td><strong>Taxa do cliente:</strong></td>
                        </tr>
                        <tr>
                          <td>{`${this.state.selectedClient.rate}`}</td>
                        </tr>
                      </React.Fragment>
                    ) : (
                      null
                    )}

                    {this.state.selectedClient && this.state.selectedClient.credit_limit ? (
                      <React.Fragment>
                        <tr>
                          <td><strong>Limite de Crédito:</strong></td>
                        </tr>
                        <tr>
                          <td>R$ {`${this.ccyFormat(this.state.selectedClient.credit_limit)}`}</td>
                        </tr>                        
                      </React.Fragment>
                    ) : (
                      null
                    )}

                    {this.state.selectedClient && this.state.selectedClient.credit_limit && this.state.selectedClient.used_credit_limit ? (
                      <React.Fragment>
                      <tr>
                        <td><strong>Limite de Crédito Usado:</strong></td>
                      </tr>
                      <tr>
                        <td>R$ {this.ccyFormat(this.state.selectedClient.used_credit_limit)}</td>
                      </tr>  
                      </React.Fragment>
                    ) : null}

                    {this.state.selectedClient && this.state.selectedClient.credit_limit && this.state.selectedClient.remaining_credit_limit ? (
                      <React.Fragment>
                      <tr>
                        <td><strong>Limite de Crédito Restante:</strong></td>
                      </tr>
                      <tr>
                        <td>R$ {this.ccyFormat(this.state.selectedClient.remaining_credit_limit)}</td>
                      </tr>  
                      </React.Fragment>
                    ) : null}
                    </tbody>
                  </table>
                </CardContent>

              </Card>


            </FormControl>

          </div>
          <Button onClick={this.handleSave} autoFocus color="primary">
            {this.state.loadingButton ? (
              <div className={classes.loading}>
                <Loading size={40} />
              </div>
            ) : !!operationIdEdit ? (
              "Editar"
            ) : (
              "Cadastrar"
            )}
          </Button>
        </Dialog>

        <ModalDelete
          name="operação"
          message="Você tem certeza que deseja excluir esta operação ?"
          open={this.state.openModalDelete}
          handleClose={() => this.handleClose("openModalDelete")}
          handleDelete={() => this.handleDelete()}
          loading={this.state.loadingButton}
        />
      </div>
    );
  }
}

OperationScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  addIcon: {
    color: "#ffff",
  },
  button: {
    backgroundColor: "#4caf50",
    marginBottom: 8,
    marginTop: 8,
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

export default withStyles(styles)(OperationScreen);
