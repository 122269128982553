import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 40,
    marginTop: 24,
  },
  div: {
    marginTop: 64,
    display: "flex",
    alignSelf: "center",
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
}));

export const Title = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <Typography variant="h4" className={classes.root}>
        {props.text}
      </Typography>
    </div>
  );
};

export default Title;
