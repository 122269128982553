import React from "react";
import "./App.css";
import Drawer from "./components/Drawer";
import { RouterLogin } from "./routes";
import { UIProvider } from "./contexts/UIContext";

function App() {
  const token = localStorage.token;
  const pathName = window.location.pathname;
  if (!token && pathName !== "/") {
    return window.location.replace("/");
  }

  return (
    <React.Fragment>
      <UIProvider>
        <div>{pathName === "/" ? <RouterLogin /> : <Drawer />}</div>
      </UIProvider>
    </React.Fragment>
  );
}

export default App;
