import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "../../components/Dialog";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import Table from "../../components/Table";
import Loading from "@material-ui/core/CircularProgress";
import ModalDelete from "../../components/ModalDelete";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import InputMask from "react-input-mask";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { instance } from "./../../config";

class EmitterScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openModalDelete: false,
      loading: false,
      loadingButton: false,
      isSearch: false,
      totalEmitters: 0,
      editIdEmitter: 0,
      searchText: "",
      idEmitterToExclude: 0,
      name: "",
      creditLimit: 0,
      usedCreditLimit: 0,
      document: "",
      documentType: "cpf",
      dataFilter: [],
      page: 0,
      count: 10,
      offset: 0,
      sortBy:  null,
      sortDirection: null,
      serverSideFilterList: "",
      data: [],
      columns: [
        {
          name: "id",
          label: "Codigo",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "name",
          label: "Nome",
          options: {
            filter: true,
            sort: true,
          },
        },

        {
          name: "credit_limit",
          label: "Limite de Crédito",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return this.ccyFormat(value || 0);
            },
          },
        },

        {
          name: "used_credit_limit",
          label: "Limite de Crédito Usado",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return this.ccyFormat(value || 0);
            },
          },
        },

        {
          name: "remaining_credit_limit",
          label: "Limite de Crédito Restante",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return this.ccyFormat(value || 0);
            },
          },
        },

        {
          name: "document",
          label: "CPF/CNPJ",
          options: {
            filter: true,
            sort: true,
            // customBodyRender: (value, tableMeta, updateValue) => {
            //   const data = this.state.data.filter(
            //     (item) => item.document === value
            //   );

            //   return (
            //     <InputMask
            //       mask={
            //         data[0].type_document === "cpf"
            //           ? "999.999.999-99"
            //           : "99.999.999/9999-99"
            //       }
            //       value={value}
            //     ></InputMask>
            //   );
            // },
          },
        },
        {
          name: "actions",
          label: "Ações",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div style={{ width: "40%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <EditIcon
                      onClick={(e) => {
                        this.setState({
                          editIdEmitter: tableMeta.rowData[0],
                          open: true,
                        });
                      }}
                    />
                    <DeleteIcon
                      onClick={() =>
                        this.setState({
                          openModalDelete: true,
                          idEmitterToExclude: tableMeta.rowData[0],
                        })
                      }
                    />
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };

    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.editIdEmitter !== this.state.editIdEmitter &&
      this.state.editIdEmitter
    ) {
      const { data } = await this.getEmitterById(this.state.editIdEmitter);
      this.setState({
        name: data.nome,
        document: data.documento,
        documentType: data.tipo_documento,
        credit_limit: data.limite_credito
      });
    }
  }

  async componentDidMount() {
    const { data } = await this.fetchData();

    this.setState({
      data: data.data,
      totalEmitters: data.totalEmitters.emitters,

      loading: false,
    });
  }

  async fetchSearchData(searchText) {
    const count = this.state.count; // limit
    const page = this.state.page; // page
    const offset = this.state.offset + count * page; //offset

    let { searchTimeout } = this.state;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (searchText && searchText.length > 0) {
      this.setState({ searchText: searchText });
      
      searchTimeout = setTimeout(async () => {
        const apiUrl = this.buildUrlParams({ count : count, offset : offset, searchText : searchText });
        const emitters = await instance().get(apiUrl);

        // totalCLients: data.data.totalEmitters.emitters,
        this.setState({
          data: emitters.data.data,
          dataFilter: emitters.data.data,
          totalCLients: emitters.data.totalEmitters.emitters
        });
      }, 500);

      this.setState({ searchTimeout: searchTimeout });
    } else {

      const apiUrl = this.buildUrlParams({ count : count, offset : offset });
      const clients = await instance().get(apiUrl);

      this.setState({ data: clients.data.data, dataFilter: [], isSearch: false, searchText: "" });
    }
  }  

  async fetchData() {
    this.setState({ loading: true });
    const API = process.env.REACT_APP_API;
    return await instance().get(`${API}/emitters`);
  }

  async getEmitterById(id) {
    const API = process.env.REACT_APP_API;
    const { data } = await instance().get(`${API}/person/${id}`);

    return data;
  }

  async handleDelete() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const { idEmitterToExclude } = this.state;

    const newDataList = this.state.data.filter(
      (item) => item.id !== idEmitterToExclude
    );
    if (idEmitterToExclude) {
      try {
        const removeEmitter = await instance().delete(
          `${API}/person/${idEmitterToExclude}`
        );
        if (removeEmitter) {
          this.setState({
            data: newDataList,
            openModalDelete: false,
            loadingButton: false,
            totalEmitters: this.state.totalEmitters - 1,
          });
        }
      } catch (err) {
        if (err.status === 400) {
          this.setState({ loadingButton: false });

          return alert(
            "Você não pode deletar este usuario, pois há vinculo com algum cheque."
          );
        }
        console.log(err);
      }
    }

    window.location.reload();
  }

  async updateEmitter() {
    this.setState({ loadingButton: true });
    const API = process.env.REACT_APP_API;

    const { name, document, documentType, editIdEmitter, credit_limit } = this.state;

    try {
      const { data } = await instance().put(`${API}/emitter/${editIdEmitter}`, {
        name: name.toLocaleUpperCase(),
        document: document.replace(/[^\d]+/g, ""),
        documentType,
        creditLimit: credit_limit
      });

      return data;
    } catch (err) {
      if (err.status === 400) {
        this.setState({ loadingButton: false });

        return alert(
          "Este CPF/CNPJ já está registrado no nosso banco de dados."
        );
      }
      console.log("err", err);
    }
  }

  async createEmitter() {
    this.setState({ loadingButton: true });
    const { name, document, documentType, credit_limit } = this.state;
    const API = process.env.REACT_APP_API;

    try {
      const { data } = await instance().post(`${API}/emitter`, {
        name: name.toLocaleUpperCase(),
        document: document.replace(/[^\d]+/g, ""),
        documentType,
        credit_limit
      });

      return data.idEmitter;
    } catch (err) {
      if (err.status === 400) {
        this.setState({ loadingButton: false });

        return alert(
          "Este CPF/CNPJ já está registrado no nosso banco de dados."
        );
      }
      console.log("err", err);
    }
  }

  handleChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (name) => {
    this.setState({
      [name]: false,
    });
  };

  errorParams() {
    const { name, document, documentType } = this.state;
    let params = [];
    let message = "Por favor, insira o(s) campo(s):";

    if (!name) {
      params.push(" nome");
    }
    if (!document || (documentType === "cpf" && !cpf.isValid(document))) {
      params.push(" CPF/CNPJ invalido");
    } else if (
      !document ||
      (documentType === "cnpj" && !cnpj.isValid(document))
    ) {
      params.push(" CPF/CNPJ invalido");
    }
    if (!documentType) {
      params.push(" tipo de documento");
    }

    if (params.length) {
      return (message += params);
    }
  }

  handleSave = async () => {
    const { editIdEmitter } = this.state;

    if (this.errorParams()) {
      return alert(this.errorParams());
    }
    const isEdit = !!editIdEmitter;

    if (isEdit) {
      const update = await this.updateEmitter();

      if (update) {
        const newDataList = this.state.data.map((item) => {
          if (item.id === editIdEmitter) {
            return {
              id: item.id,
              name: this.state.name,
              document:
                this.state.document &&
                this.state.document.replace(/[^\d]+/g, ""),
              documentType: this.state.documentType,
              credit_limit: this.state.credit_limit
            };
          }
          return { ...item };
        });

        this.setState({
          open: false,

          data: newDataList,
          loadingButton: false,
        });
      }
    } else {
      const emitterId = await this.createEmitter();

      if (emitterId) {
        const newValue = {
          id: emitterId,
          name: this.state.name,
          document:
            this.state.document && this.state.document.replace(/[^\d]+/g, ""),
          documentType: this.state.documentType,
        };

        this.setState({
          data: [...this.state.data, newValue],
          name: "",
          document: "",
          documentType: "",
          open: false,
          credit_limit: 0,
          loadingButton: false,
        });
      }
    }

    window.location.reload();
  };
  
  changePage = async (page) => {
    const { count, offset, searchText } = this.state;
    const API = process.env.REACT_APP_API;
  
    const newOffset = offset + count * page;
    const apiUrl = `${API}/emitters?limit=${count}&offset=${newOffset}${searchText ? `&filter=${searchText}` : ''}`;
  
    try {
      const response = await instance().get(apiUrl);
  
      const newState = {
        count,
        data: response.data.data,
        dataFilter: searchText ? response.data.data : [],
        isSearch: false,
        searchText: searchText || "",
      };
  
      this.setState(newState);
    } catch (error) {
      // Handle error appropriately
      console.error("Error fetching data:", error);
    }
  };
  
  changeSearch = async (searchText) => {
    this.fetchSearchData(searchText);
  };


  handleChangeRowsPerPage = async (value) => {
    const count = value; // limit
    const offset = this.state.offset; //offset
    const { searchText } = this.state;

    const apiUrl = this.buildUrlParams({ count : count, offset : offset, searchText : searchText });

    const emitters = await instance().get(apiUrl);

    let state = { count: value, data: emitters.data.data, dataFilter: [] , isSearch: false, searchText: ""};
    if (searchText) {
      state = { count: value, data: emitters.data.data , dataFilter: emitters.data.data};
    }

    this.setState(state);
  };

  buildUrlParams( params ) {
    const API = process.env.REACT_APP_API;

    const { count, offset, searchText } = params;
    const queryParams = [];
  
    if (count !== undefined) {
      queryParams.push(`limit=${count}`);
    }
  
    if (offset !== undefined) {
      queryParams.push(`offset=${offset}`);
    }
  
    if (searchText !== undefined) {
      queryParams.push(`filter=${searchText}`);
    }
  
    if (this.state.sortBy !== null) {
      queryParams.push(`sort=${this.state.sortBy}`);
    }
  
    if (this.state.sortDirection !== null) {
      queryParams.push(`sort_direction=${this.state.sortDirection}`);
    }
    const queryString = queryParams.join("&");
  
    return `${API}/emitters${queryString ? `?${queryString}` : ''}`;
  }

  ccyFormat(num) {
    return `R$${num
      .toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      .replace(".", ",")
      .replace(",", ".")}`;
  }

  render() {
    const { classes } = this.props;
    const {
      open,
      name,
      credit_limit,
      columns,
      loading,
      page,
      count,
      searchText,
      totalEmitters,
    } = this.state;

    return (
      <div>
        <div className={classes.content}>
          <React.Fragment>
            {loading ? (
              <div className={classes.loading}>
                <Loading size={40} />
              </div>
            ) : (
              <Table
                page={page}
                rowsPerPage={count}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                searchText={searchText}
                onTableChange={async (action, tableState) => {
                  switch (action) {
                    case "changePage":
                      this.changePage(tableState.page);
                      break;
                    case "search":
                      this.setState({
                        isSearch: true,
                      });
                      this.changeSearch(tableState.searchText);
                      break;
                    case "onSearchClose":
                      this.setState({
                        isSearch: false,
                        searchText: "",
                      });
                      this.changePage(tableState.page);
                      break;
                    case "sort":
                        const sortState = tableState.columns.find((f) => f.sortDirection !== 'none');
                        this.setState({
                          sortBy: sortState.name,
                          sortDirection: sortState.sortDirection,
                        }, () => this.fetchSearchData());
                        
                        break;                      
                    default:
                      break;
                  }
                }}
                count={totalEmitters}
                titleText="Emitentes"
                data={
                  this.state.isSearch === true
                    ? this.state.dataFilter
                    : this.state.data
                }
                columns={columns}
              >
                <Button
                  onClick={() =>
                    this.setState({
                      open: true,
                      editIdEmitter: 0,
                      name: "",
                      document: "",
                      documentType: "",
                      credit_limit: 0 
                    })
                  }
                  className={classes.button}
                >
                  <AddIcon className={classes.addIcon} fontSize="large" />
                </Button>
              </Table>
            )}
          </React.Fragment>
        </div>

        <Dialog
          onClose={() => this.handleClose("open")}
          type="client"
          open={open}
          title={
            this.state.editIdEmitter ? "Editar emitente" : "Adicionar emitente"
          }
        >
          <div className={classes.dialogContent}>
            <TextField
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
              style={{ marginBottom: 24, marginTop: 24 }}
              required
              label="Nome"
            />

            <FormControl style={{ marginBottom: 12 }} component="fieldset">
              <RadioGroup
                value={this.state.documentType}
                onChange={(e) =>
                  this.setState({ documentType: e.target.value })
                }
              >
                <FormControlLabel
                  value="cpf"
                  control={<Radio color="primary" />}
                  label="CPF"
                />
                <FormControlLabel
                  value="cnpj"
                  control={<Radio color="primary" />}
                  label="CNPJ"
                />
              </RadioGroup>
            </FormControl>

            {this.state.documentType && (
              <InputMask
                mask={
                  this.state.documentType === "cpf"
                    ? "999.999.999-99"
                    : "99.999.999/9999-99"
                }
                value={this.state.document}
                onChange={(e) => this.setState({ document: e.target.value })}
                className={this.props.classes.textField}
              >
                {() => (
                  <TextField
                    required
                    label={this.state.documentType === "cpf" ? "CPF" : "CNPJ"}
                  />
                )}
              </InputMask>
            )}

            <CurrencyTextField
              label="Limite"
              variant="standard"
              value={credit_limit}
              currencySymbol="R$"
              decimalCharacter=","
              digitGroupSeparator="."
              outputFormat="string"
              onChange={(e, value) => this.setState({ credit_limit: value })}
            />
          </div>
          <Button onClick={this.handleSave} autoFocus color="primary">
            {this.state.loadingButton ? (
              <div className={classes.loading}>
                <Loading size={40} />
              </div>
            ) : this.state.editIdEmitter ? (
              "Editar"
            ) : (
              "Cadastrar"
            )}
          </Button>
        </Dialog>

        <ModalDelete
          name="emitente"
          message="Você tem certeza que deseja excluir este emitente ?"
          open={this.state.openModalDelete}
          handleClose={() => this.handleClose("openModalDelete")}
          handleDelete={() => this.handleDelete()}
          loading={this.state.loadingButton}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  addIcon: {
    color: "#ffff",
  },
  button: {
    backgroundColor: "#4caf50",
    width: 50,
    marginBottom: 8,
    marginTop: 8,
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 48,
    marginRight: 48,
    marginLeft: 48,
  },
  textField: {
    margin: 18,
  },
  loading: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

EmitterScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmitterScreen);
