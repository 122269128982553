import axios from "axios";

export const instance = () => {
  const i = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 10000,
    headers: { "x-access-token": localStorage.getItem("token") },
  });

  i.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.log("error", error.response);
      if (error.response && error.response.status === 401) {
        localStorage.setItem("token", null);
        window.location.replace("/");
      }

      return Promise.reject(error.response);
    }
  );

  return i;
};

export default instance;
