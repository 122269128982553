import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Title from "./../../../components/Title";
import { InputLabel,  MenuItem,  Select,  Typography } from "@material-ui/core";
import Loading from "@material-ui/core/CircularProgress";
import { instance } from "./../../../config";
import { navigate } from "@reach/router";

class ReportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: Moment().subtract(3, "days").format("DD-MM-YYYY"),
      endDate: Moment().add(3, "days").format("DD-MM-YYYY"),
      filter: "day",
      sort: "week asc",
      dayReports: [],
      weekReports: [],
      totalValue: 0,
      isLoading: false,
      isSearching: false
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.startDate !== this.state.startDate && this.state.startDate) ||
      (prevState.endDate !== this.state.endDate && this.state.endDate)
    ) {
      if (this.state.isSearching) {
        clearTimeout(this.state.isSearching);
      }

      this.state.isSearching = setTimeout(() => {
        this.fetchReport();
      }, 300);
    }
  }

  async fetchReport() {
    if (this.state.filter === "day") {
      this.fetchDataDay();
    } else if (this.state.filter === "week") {
      this.fetchDataWeek();
    }
  }

  async componentDidMount() {
    await this.fetchDataDay();
  }

  async fetchDataDay() {
    this.setState({ isLoading: true });
    const API = process.env.REACT_APP_API;

    const report = await instance().get(
      `${API}/reports/totalOperation/day?initialDate=${this.state.startDate}&finalDate=${this.state.endDate}&sort=${this.state.sort}`
    );

    if (report) {
      this.setState({
        dayReports: report.data.data,
        totalValue: report.data.totalValue,
        isLoading: false,
      });
    }
  }

  async fetchDataWeek() {
    this.setState({ isLoading: true });
    const API = process.env.REACT_APP_API;
    const report = await instance().get(
      `${API}/reports/totalOperation/week?initialDate=${this.state.startDate}&finalDate=${this.state.endDate}&sort=${this.state.sort}`
    );

    this.setState({
      weekReports: report.data.data,
      totalValue: report.data.totalValue,
      isLoading: false,
    });
  }

  handleChangeSort = (e) => {
    this.setState({sort : e.target.value}, () => this.fetchReport());
  }

  handleFilter = (e) => {
    this.setState({ loading: true, filter: e.target.value });
    const filter = e.target.value;
    if (filter === "day") {
      this.fetchDataDay();
    }
    if (filter === "week") {
      this.fetchDataWeek();
    }

    this.setState({ loading: false });
  };

  handleDayDetail = (row) => {
    navigate(`/relatorio/cheques-a-descontar-por-cliente`, { state: {
      filter: "day",
      startDate: row.dueDate,
      endDate: row.dueDate,
     }})
     
  };

  handleWeekDetail = (row) => {
    navigate(`/relatorio/cheques-a-descontar-por-cliente`, { state: {
      filter: "week",
      startDate: row.initial,
      endDate: row.final,
     }})
  };

  renderReportTable(dayReports, totalValue) {
    const { classes } = this.props;
    const { filter, weekReports } = this.state;

    function ccyFormat(num) {
      return `R$${num
        .toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .replace(".", ",")
        .replace(",", ".")}`;
    }

    if (filter === "day") {
      if (!dayReports.length) {
        return (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 64,
            }}
          >
            <Typography>Você não possui registros</Typography>
          </Grid>
        );
      } else {
        return (
          <TableContainer className={classes.table} component={Paper}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Detalhes
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Semana</TableCell>
                  <TableCell align="right">Dia</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dayReports &&
                  dayReports.map((row, index) => (

                    <TableRow key={index} onClick={() => this.handleDayDetail(row)}>
                      <TableCell>{row.week}</TableCell>
                      <TableCell align="right">
                          <u>{row.dueDate}</u>
                      </TableCell>
                      <TableCell align="right">{ccyFormat(row.total)}</TableCell>
                    </TableRow>
                  ))}

                <TableRow>
                  <TableCell rowSpan={3} />

                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                    {ccyFormat(totalValue && totalValue)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    }

    if (filter === "week") {
      if (!weekReports.length) {
        return (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 64,
            }}
          >
            <Typography>Você não possui registros</Typography>
          </Grid>
        );
      } else {
        return (
          <TableContainer className={classes.table} component={Paper}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Detalhes
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Semana</TableCell>
                  <TableCell align="right">Inicial</TableCell>
                  <TableCell align="right">Final</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {weekReports &&
                  weekReports.map((row, index) => (
                    <TableRow key={index}  onClick={() => this.handleWeekDetail(row)}>
                      <TableCell>
                          <u>{row.week}</u>
                      </TableCell>
                      <TableCell align="right"><u>{row.initial}</u></TableCell>
                      <TableCell align="right"><u>{row.final}</u></TableCell>
                      <TableCell align="right">{ccyFormat(row.total && row.total)}</TableCell>
                    </TableRow>
                  ))}

                <TableRow>
                  <TableCell rowSpan={3} />

                  <TableCell rowSpan={3} />
                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                    {ccyFormat(totalValue && totalValue)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      startDate,
      endDate,
      dayReports,
      totalValue,
      isLoading,
      filter,
    } = this.state;

    return (
      <div className={classes.content}>
        <Grid style={{ display: "flex", justifyContent: "center" }}>
          <Title text="Cheques a descontar" />
        </Grid>
        <Grid
          style={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
        >
          <FormControl style={{ marginBottom: 24 }} component="fieldset">
            <RadioGroup
              value={this.state.filter}
              style={{ display: "flex", flexDirection: "row" }}
              onChange={this.handleFilter}
            >
              <FormControlLabel
                value="week"
                control={<Radio color="primary" />}
                label="por semana"
              />
              <FormControlLabel
                value="day"
                style={{ marginLeft: 8 }}
                control={<Radio color="primary" />}
                label="por dia"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                id="date"
                label="Inicial"
                type="date"
                value={Moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                onChange={(e) =>
                  this.setState({
                    startDate: Moment(e.target.value).format("DD/MM/YYYY"),
                  })
                }
                defaultValue={startDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="date"
              label="Final"
              type="date"
              value={Moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
              onChange={(e) =>
                this.setState({
                  endDate: Moment(e.target.value).format("DD/MM/YYYY"),
                })
              }
              defaultValue={endDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid container spacing={2} justify="center">
            <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <FormControl style={{ minWidth: "200px", alignSelf: "center" }}>
                <InputLabel id="sortorder-label">Ordenar Por</InputLabel>
                <Select
                  labelId="sortorder-label"
                  id="sortorder"
                  value={this.state.sort}
                  label="Ordernar Por"
                  onChange={(e) => this.handleChangeSort(e)}
                >
                  <MenuItem value={'week asc'}>Semana Crescente</MenuItem>
                  <MenuItem value={'week desc'}>Semana Decrescente</MenuItem>
                  <MenuItem value={'total asc'}>Valor Crescente</MenuItem>
                  <MenuItem value={'total desc'}>Valor Decrescente</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>          
        </Grid>

        {isLoading ? (
          <div className={classes.loading}>
            <Loading size={40} />
          </div>
        ) : (
          this.renderReportTable(dayReports, totalValue)
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  loading: {
    marginTop: 36,
    display: "flex",
    justifyContent: "center",
  },
  table: {
    marginTop: 64,
    display: "flex",
    alignSelf: "center",
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
});

export default withStyles(styles)(ReportScreen);
