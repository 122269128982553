import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  IconButton,
  Typography,
  Table,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import Title from "./../../../components/Title";
import SearchIcon from "@material-ui/icons/SearchTwoTone";
import Loading from "@material-ui/core/CircularProgress";
import { instance } from "./../../../config";
import Moment from "moment"

class SearchBankCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeFilter: "emitter",
      search: "",
      isLoading: false,
      totalValue: "",
      data: [],
      startDate: Moment().subtract(3, "days").format("YYYY-MM-DD"),
      endDate: Moment().add(5, "days").format("YYYY-MM-DD"),
    };
    this.handleFilter = this.handleFilter.bind(this);
  }

  async fetchData() {
    this.setState({ isLoading: true });
    const API = process.env.REACT_APP_API;
    const { typeFilter, search } = this.state;

    const data = await instance().get(
      `${API}/reports/searchBankChecks?typeFilter=${typeFilter}&search=${search}&initialDate=${this.state.startDate}&finalDate=${this.state.endDate}`
    );
    if (data) {
      const totalValue = data.data.data
        .reduce((prev, acc) => {
          return prev + acc.value;
        }, 0)
        .toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .replace(".", ",")
        .replace(",", ".");

      this.setState({ data: data.data.data, isLoading: false, totalValue });
    }
  }

  async handleFilter() {
    await this.fetchData();
  }

  handleChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  renderTable() {
    const { data, totalValue } = this.state;
    const { classes } = this.props;
    if (!data.length) {
      return (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 64,
          }}
        >
          <Typography>Você não possui registros</Typography>
        </Grid>
      );
    }
    return (
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Detalhes
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Código operação</TableCell>
              <TableCell align="center">Negocio</TableCell>
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Emitente</TableCell>
              <TableCell align="center">Vencimento</TableCell>
              <TableCell align="center">Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.idOperation}</TableCell>
                  <TableCell align="center">{row.dateOperation}</TableCell>
                  <TableCell align="center">{row.clientName}</TableCell>
                  <TableCell align="center">{row.emitterName}</TableCell>
                  <TableCell align="center">{row.dueDate}</TableCell>
                  <TableCell align="center">
                    {row.value &&
                      "R$ " +
                        row.value
                          .toLocaleString("pt-BR", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                          .replace(".", ",")
                          .replace(",", ".")}
                  </TableCell>
                </TableRow>
              ))}

            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />

              <TableCell colSpan={2} align="center">
                Total
              </TableCell>

              <TableCell align="center">
                {totalValue && "R$" + totalValue}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  u;

  render() {
    const { classes } = this.props;
    const { typeFilter, search, isLoading } = this.state;
    return (
      <div className={classes.content}>
        <Title text="Pesquisa de cheques" />
        <Grid
          style={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
        >
          <FormControl style={{ marginBottom: 24 }} component="fieldset">
            <RadioGroup
              value={typeFilter}
              style={{ display: "flex", flexDirection: "row" }}
              onChange={this.handleChange("typeFilter")}
            >
              <FormControlLabel
                value="client"
                control={<Radio color="primary" />}
                label="Cliente"
              />
              <FormControlLabel
                value="emitter"
                style={{ marginLeft: 8 }}
                control={<Radio color="primary" />}
                label="Emitente"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 32 }}>
              <TextField
                id="date"
                label="Inicial"
                type="date"
                value={this.state.startDate}
                onChange={(e) =>
                  this.setState({
                      startDate: e.target.value,
                     })
                    }
                defaultValue={this.state.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="date"
              label="Final"
              type="date"
              value={this.state.endDate}
                onChange={(e) =>
                 this.setState({
                     endDate: e.target.value,
                   })
                 }
              defaultValue={this.state.endDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
          spacing
          container={2}
        >
          <TextField
            id="standard-basic"
            value={search}
            onChange={this.handleChange("search")}
            label="Buscar"
          />
          <IconButton onClick={this.handleFilter} aria-label="delete">
            <SearchIcon />
          </IconButton>
        </Grid>

  

        {isLoading ? (
          <div className={classes.loading}>
            <Loading size={40} />
          </div>
        ) : (
          this.renderTable()
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  loading: {
    marginTop: 36,
    display: "flex",
    justifyContent: "center",
  },
  table: {
    marginTop: 64,
    display: "flex",
    alignSelf: "center",
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
});

export default withStyles(styles)(SearchBankCheck);
