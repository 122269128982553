import React from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import Title from "./../Title";

export const Table = (props) => {
  const classes = useStyles();

  const options = {
    print: props.print === false ? false : true,
    selectableRows: false,
    serverSide: true,
    filter: false,
    serverSideFilterList: props.serverSideFilterList,
    onTableChange: props.onTableChange,
    page: props.page,
    count: props.count,
    searchText: props.searchText,
    onFilterChange: props.onFilterChange,
    rowsPerPage: props.rowsPerPage,
    //onChangePage: props.onChangePage,
    onPageChange: props.onChangePage,
    // onChangeRowsPerPage: props.onChangeRowsPerPage,
    onRowsPerPageChange: props.onChangeRowsPerPage,
    search: props.search === false ? false : true,
    filterType: "dropdown",
    responsive: "scroll",
    pagination: props.pagination === false ? false : true,
    textLabels: {
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Visualizar colunas",
        filterTable: "Filtrar tabela",
      },
      pagination: {
        next: "Próxima Pagina",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "of",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Excluir",
        deleteAria: "Excluir Linhas Selecionadas",
      },
      body: {
        noMatch: "Desculpe, nenhum registro correspondente encontrado",
        toolTip: "Sort",
      },
    },
  };
  return (
    <div className={classes.table}>
      <Title text={props.titleText} />
      {props.children}
      <MUIDataTable
        title={props.title}
        data={props.data}
        columns={props.columns}
        options={options}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },
}));

export default Table;
